import * as _ from 'lodash';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { Filter } from 'upmesh-core/src/client/query/filter/Filter';
import { UpmeshPartnerSettingsEntity } from 'upmesh-support-core/src/entities/UpmeshPartnerSettingsEntity';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { SubscriptionChart } from './SubscriptionChart';
import { SubscriptionExcelExport } from './SubscriptionExcelExport';
export class SubscriptionView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.baseData = [];
        this.openHistoryDialogSync = (price) => (_e) => {
            this.openHistoryDialog(price).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.openHistoryDialog = async (price) => {
            LoadingEvents.instance.startLoading();
            try {
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    LoadingEvents.instance.stopLoading();
                    Dialog.instance?.open({
                        showCloseIcon: true,
                        fullscreen: true,
                        content: <SubscriptionChart price={price}/>,
                    });
                }
            }
            catch (e) {
                LoadingEvents.instance.stopLoading();
                console.debug('cant get online users', e);
            }
        };
        this.tableProps = [
            {
                title: I18n.m.getMessage('userOrCompany'),
                keyInData: 'name',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('type'),
                keyInData: 'subscriptionText',
                style: { width: 270 },
                sortable: true,
                dataType: 'string',
                minWidth: 270,
            },
            {
                title: 'test?',
                keyInData: 'testAccount',
                style: { width: 100 },
                sortable: true,
                dataType: 'string',
                minWidth: 100,
                cellRenderer: (item, column, _index) => {
                    return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText>{item.testAccount ? I18n.m.getMessage('yes') : I18n.m.getMessage('no')}</MaterialText>
          </View>);
                },
            },
            {
                title: I18n.m.getMessage('start'),
                keyInData: 'startDate',
                style: { width: 160 },
                dataType: 'Date',
                minWidth: 160,
            },
            {
                title: I18n.m.getMessage('end'),
                keyInData: 'expireDate',
                style: { width: 160 },
                dataType: 'Date',
                minWidth: 160,
            },
            {
                title: I18n.m.getMessage('amount'),
                keyInData: 'licenses',
                style: { width: 130 },
                sortable: true,
                dataType: 'number',
                minWidth: 100,
            },
            {
                title: 'Abrechnungsintervall in Monaten',
                dataType: 'number',
                keyInData: 'paymentIntervallInMonth',
                style: { minWidth: 170 },
                minWidth: 170,
            },
            {
                title: 'Mindestvertragslaufzeit in Monaten',
                dataType: 'number',
                keyInData: 'minimumTermInMonth',
                style: { minWidth: 180 },
                minWidth: 180,
            },
            {
                title: 'Einmalig',
                dataType: 'number',
                keyInData: 'oneTimePrice',
                style: { minWidth: 160 },
                minWidth: 160,
                format: (v) => v.oneTimePrice
                    ? v.oneTimePrice.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
                    : '-',
            },
            {
                title: 'Einzelpreis pro Monat',
                keyInData: 'price',
                style: { width: 250 },
                dataType: 'number',
                minWidth: 180,
                format: (v) => v.price
                    ? v.price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
                    : '-',
            },
            {
                title: 'Gesamteinnahmen pro Monat',
                keyInData: 'totalPrice',
                style: { width: 250 },
                dataType: 'number',
                minWidth: 180,
                format: (v) => v.totalPrice
                    ? v.totalPrice.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
                    : '-',
            },
            {
                title: 'Ausgaben pro Monat',
                keyInData: 'costs',
                style: { width: 250 },
                dataType: 'number',
                minWidth: 180,
                format: (v) => v.costs
                    ? v.costs.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
                    : '-',
            },
            {
                title: 'Gewinn pro Monat',
                keyInData: 'profit',
                style: { width: 250 },
                dataType: 'number',
                minWidth: 180,
                format: (v) => v.profit
                    ? v.profit.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    })
                    : '-',
            },
        ];
        this.reInit = (_e) => {
            this.init().catch((err) => console.debug(err));
        };
        this.partners = {};
        this.exportFor = async (date) => {
            await SubscriptionExcelExport.createExcel(date);
        };
        this.export = (e) => {
            Datepicker.open({
                labelText: 'Monat wählen',
                onChange: (d) => {
                    this.exportFor(d).catch(DefaultErrorHandler.showDefaultErrorAlert);
                },
                mode: 'date',
            }, e);
        };
        this.openRow = (s) => {
            if (s.userId != null) {
                Routing.instance.openDialog('user', { id: s.userId })(null);
            }
            else if (s.companyId != null) {
                Routing.instance.openDialog('company', { id: s.companyId })(null);
            }
        };
        this.setFilter = _.debounce((text) => {
            Routing.instance.changeQueryParameter({ search: text }, true);
        }, 300);
        this.filter = (text) => {
            const user = [...this.baseData];
            if (text.length === 0) {
                return user;
            }
            const words = text.trim().split(' ');
            const filterBy = (key) => (element, _index, _array) => {
                for (let i = 0; i < words.length; i += 1) {
                    if (words[i] != null && words[i].length > 0) {
                        const regexp = new RegExp(Filter.escapeRegExp(words[i]), 'i');
                        const val = element[key];
                        if (val != null) {
                            const matchA = val.toString().match(regexp);
                            if (matchA != null && matchA.length > 0) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            };
            const filteredByCompany = user.filter(filterBy('name'));
            const filteredByType = user.filter(filterBy('subscriptionText'));
            const result = [...filteredByCompany, ...filteredByType];
            const counts = [];
            result.forEach((item) => {
                if (counts[item.id] === undefined) {
                    counts[item.id] = 0;
                }
                counts[item.id] += 1;
                return counts;
            });
            const unique = result.filter((x, i, l) => l.indexOf(x) === i);
            const filtered = unique.sort((v1, v2) => counts[v2.id] - counts[v1.id]);
            return filtered;
        };
        if (!ConnectionHandler.isPartner) {
            this.tableProps.push({
                title: I18n.m.getMessage('partner'),
                keyInData: 'partner',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            });
        }
        this.state = {
            subscriptions: [],
        };
    }
    componentDidMount() {
        this.attachkey = ConnectionHandler.getClient()?.eventDispatcher.attach({
            readModelName: 'Subscription',
            callback: this.reInit,
        });
        this.mounted = true;
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (this.attachkey != null) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('Subscription', this.attachkey);
        }
        this.mounted = false;
    }
    async getPartner(item) {
        if (item.partnerId == null)
            return 'upmesh';
        if (this.partners[item.partnerId] != null)
            return this.partners[item.partnerId];
        const partner = await ConnectionHandler.getClient()?.upmeshPartner.getById(item.partnerId);
        if (partner != null) {
            this.partners[item.partnerId] = partner.company;
        }
        return partner != null ? partner.company : '?';
    }
    async getPrice(s) {
        if (s.testAccount) {
            return { price: 0, totalPrice: 0, costs: 0, profit: 0 };
        }
        const count = s.licenses == null ? 1 : s.licenses;
        let p = { price: 0, totalPrice: 0 };
        let costs = 0;
        if (s.price != null && s.price > 0) {
            p = { price: s.price, totalPrice: s.price * count };
        }
        if (s.partnerId != null) {
            try {
                let partner = new UpmeshPartnerSettingsEntity({ id: s.partnerId });
                try {
                    const ps = await ConnectionHandler.getClient()?.upmeshPartnerSettings.getById(s.partnerId);
                    if (ps)
                        partner = ps;
                }
                catch (e) {
                    console.debug('cant get partner', e);
                }
                if (partner != null) {
                    if (ConnectionHandler.isPartner) {
                        if (!p.price) {
                            let price = partner.sellingPrice;
                            if (s.licenceType === 'collaborator')
                                price = partner.sellingPriceCollaborator;
                            else if (s.licenceType === 'timeTracking')
                                price = partner.sellingPriceTimeTracking;
                            p = { price, totalPrice: price * count };
                        }
                        costs = partner.price * count;
                        if (s.licenceType === 'collaborator')
                            costs = partner.priceCollaborator * count;
                        else if (s.licenceType === 'timeTracking')
                            costs = partner.priceTimeTracking * count;
                    }
                    else {
                        if (!p.price) {
                            let price = partner.sellingPrice;
                            if (s.licenceType === 'collaborator')
                                price = partner.sellingPriceCollaborator;
                            else if (s.licenceType === 'timeTracking')
                                price = partner.sellingPriceTimeTracking;
                            p = { price, totalPrice: price * count };
                        }
                        costs = p.totalPrice - partner.price * count;
                        if (s.licenceType === 'collaborator')
                            costs = p.totalPrice - partner.priceCollaborator * count;
                        else if (s.licenceType === 'timeTracking')
                            costs = p.totalPrice - partner.priceTimeTracking * count;
                    }
                }
            }
            catch (e) {
                console.debug('cant get partner', e);
            }
        }
        if (!p.price) {
            let price = 69.9;
            if (s.licenceType === 'collaborator')
                price = 19.9;
            else if (s.licenceType === 'timeTracking')
                price = 4.5;
            p = { price, totalPrice: price * count };
        }
        return { ...p, costs, profit: p.totalPrice - costs };
    }
    async init() {
        const client = ConnectionHandler.getClient();
        if (client != null) {
            const subs = await client.subscription.get({
                filter: `expireDate gt ${new Date().toISOString()} and (subscription eq 'pro' or subscription eq 'enterprise')`,
            });
            const subscriptions = [];
            const p = [];
            for (const s of subs) {
                p.push(new Promise((resolve) => {
                    const asyncNow = async () => {
                        try {
                            if (!ConnectionHandler.isPartner) {
                                s['partner'] = await this.getPartner(s);
                            }
                            const item = s;
                            const price = await this.getPrice(s);
                            item.price = price.price;
                            item.totalPrice = price.totalPrice;
                            item.costs = price.costs;
                            item.profit = price.profit;
                            item.name = '-';
                            item.subscriptionText = item.subscription;
                            if (item.licenceType === 'timeTracking')
                                item.subscriptionText += ` (${I18n.m.getMessage('timeTracking')})`;
                            else if (item.licenceType === 'collaborator')
                                item.subscriptionText += ` (${I18n.m.getMessage('companyUserLicenseCollaborator')})`;
                            else if (item.licenceType == null && item.subscription === 'enterprise')
                                item.subscriptionText += ` (${I18n.m.getMessage('companyUserLicenseFull')})`;
                            if (s.userId != null) {
                                try {
                                    const user = await ConnectionHandler.getClient()?.user.getById(s.userId);
                                    item.name = `${user.getFullName()} (${user.emails?.join(', ')})`;
                                }
                                catch (err) {
                                    console.debug('cant get user', err);
                                    item.name = '?';
                                }
                            }
                            else if (s.companyId != null) {
                                try {
                                    item.name = (await ConnectionHandler.getClient()?.company.getById(s.companyId)).company;
                                }
                                catch (err) {
                                    console.debug('cant get cimpany', err);
                                    item.name = '?';
                                }
                            }
                            subscriptions.push(item);
                        }
                        catch (e) {
                            console.debug('cant load subscription', e);
                        }
                        resolve();
                    };
                    asyncNow().catch((err) => console.error(err));
                }));
            }
            await Promise.all(p);
            this.baseData = subscriptions;
            this.setState({ subscriptions });
        }
        else {
            requestAnimationFrame(() => {
                Routing.instance.goTo('/login');
            });
        }
    }
    render() {
        const { search } = this.props;
        const { subscriptions } = this.state;
        const filtered = search != null && search.length > 0 ? this.filter(search) : subscriptions;
        const rightButtons = [
            <Icon toolTip="Preise" icon="history" key="info" onPress={this.openHistoryDialogSync(true)}/>,
            <Icon toolTip="Anzahl" icon="history" key="info" onPress={this.openHistoryDialogSync(false)}/>,
        ];
        if (ConnectionHandler.isPartner) {
            rightButtons.unshift(<Icon toolTip="Export" icon="export" key="info" onPress={this.export}/>);
        }
        return (<Page rightButtons={rightButtons} pageTitle={`${I18n.m.getMessage('subscriptions')} (${subscriptions.length})`}>
        <View style={{
                width: '100%',
                marginTop: 4,
            }}>
          <View style={{
                width: '100%',
                paddingLeft: 8,
            }}>
            <SearchBar hoverColor={ThemeManager.style.searchBarBackground} searchOnChange={this.setFilter} searchBarValue={search != null ? search : ''}/>
          </View>
          <Card style={{ width: '100%', height: 'auto' }}>
            <Table tableName="Abos" maxHeight={ResizeEvent.current.contentHeight - 156} actionItemsLength={0} emptyTableText="" emptyTableHint="" data={filtered} onRowPress={this.openRow} columns={this.tableProps}/>
          </Card>
        </View>
      </Page>);
    }
}
