import { ReadModels } from 'cqrs-core/src/core/query/ReadModels';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Picker } from 'materialTheme/src/theme/components/Picker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, StyleSheet, UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddModuleToCompany, } from 'upmesh-support-core/src/commands/company/AddModuleToCompany';
import { AddNewUserToCompany, } from 'upmesh-support-core/src/commands/company/AddNewUserToCompany';
import { AddUserToCompany } from 'upmesh-support-core/src/commands/company/AddUserToCompany';
import { ChangeAccountManager } from 'upmesh-support-core/src/commands/company/ChangeAccountManager';
import { ChangeCompany } from 'upmesh-support-core/src/commands/company/ChangeCompany';
import { ChangeCompanyUserRole } from 'upmesh-support-core/src/commands/company/ChangeCompanyUserRole';
import { RemoveModuleFromCompany, } from 'upmesh-support-core/src/commands/company/RemoveModuleFromCompany';
import { RemoveUserFromCompany } from 'upmesh-support-core/src/commands/company/RemoveUserFromCompany';
import { UpdateCompanyAccess } from 'upmesh-support-core/src/commands/company/UpdateCompanyAccess';
import { CompanyPaymentEntity } from 'upmesh-support-core/src/entities/CompanyPaymentEntity';
import * as uuid from 'uuid';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { ChangeCompanyPaymentDialog } from './ChangeCompanyPaymentDialog';
import { CompanyNotes } from './CompanyNotes';
import { CompanyPayments } from './CompanyPayments';
import { CompanyProjects } from './CompanyProjects';
import { CompanySubscriptions } from './CompanySubscriptions';
export class CompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.currentUserAddData = {
            email: '',
            firstname: '',
            lastname: '',
        };
        this.onChangeTab = (e) => {
            Routing.instance.changeQueryParameter({ data: { id: this.props.data.id, tab: e.toString() } }, true);
            this.setState({ currentTab: e });
        };
        this.updateCompanyAccess = () => {
            Routing.instance.alert.post({
                text: 'Firmenzugriff neu erstellen?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="neu erstellen" onPress={this.updateCompanyNow}/>,
                ],
            });
        };
        this.updateCompanyNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { company } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (company == null || token == null)
                    return null;
                const r = new UpdateCompanyAccess({}, company.id, token);
                LoadingEvents.instance.stopLoading();
                const start = Date.now();
                Toast.instance?.open({
                    title: 'Die Firmenzugriffsrechte werden neu ertsellt',
                });
                r.execute(ConnectionHandler.getStore())
                    .then(() => {
                    Toast.instance?.open({
                        title: `Die Firmenzugriffsrechte wurden in "${(Date.now() - start) / 1000}" Sekunden neu ertsellt`,
                    });
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                });
                return null;
            });
        };
        this.changeLicence = () => {
            console.debug('change License');
        };
        this.close = (e) => {
            Routing.instance.closeDialog(true)(e);
        };
        this.edit = (e) => {
            Measurement.measure(e.currentTarget)
                .then((p) => {
                const client = {
                    height: p.height,
                    width: p.width,
                    x: p.pageX,
                    y: p.pageY,
                };
                const items = [
                    {
                        onPress: this.editData,
                        text: 'Daten bearbeiten',
                    },
                    {
                        onPress: this.editPrices,
                        text: 'Preise bearbeiten',
                    },
                ];
                Menu.instance?.open({
                    client,
                    items,
                    formInput: false,
                });
            })
                .catch((err) => {
                console.debug('cant open Menu', err);
            });
        };
        this.editData = (e) => {
            Menu.instance?.close();
            Routing.instance.openDialog('companyEdit', { id: this.props.data.id })(e);
        };
        this.editPrices = (e) => {
            Menu.instance?.close();
            ChangeCompanyPaymentDialog.openDialog(this.props.data.id)(e);
        };
        this.closeAdd = () => {
            this.setState({ addUser: false, addNewUser: false });
        };
        this.modules = [
            { title: I18n.m.getMessage('moduleTimeTracking'), id: 'timeTracking', button: false },
            { title: I18n.m.getMessage('moduleMaps'), id: 'maps', button: false },
            { title: I18n.m.getMessage('moduleTimeTrackingJournalEdit'), id: 'timeTrackingJournalEdit', button: false },
            { title: I18n.m.getMessage('moduleUpmeshKi'), id: 'moduleUpmeshKi', button: false },
            { title: I18n.m.getMessage('moduleCompanyLogo'), id: 'moduleCompanyLogo', button: false },
        ];
        this.init = () => {
            const asyncNow = async () => {
                const client = ConnectionHandler.getClient();
                const { data } = this.props;
                const users = [];
                let companyUsers = [];
                let partner;
                let partners = [];
                const activeModules = [];
                if (client != null && data != null && data.id != null) {
                    const company = await client.company.getById(data.id);
                    if (company == null)
                        return;
                    if ((!ConnectionHandler.isPartner && company.partnerId != null) ||
                        (company.partnerId != null && company.partnerId === ConnectionHandler.partnerId)) {
                        partner = await client.upmeshPartner.getById(company.partnerId);
                    }
                    if (ConnectionHandler.myRole?.role === 'Admin')
                        partners = await client.upmeshPartner.get({ orderby: 'company' });
                    const companySettings = await client.companySettings.getById(data.id);
                    let accountManager;
                    if (companySettings.accountManager != null && companySettings.accountManager.length > 0) {
                        try {
                            accountManager = await client.user.getById(companySettings.accountManager);
                        }
                        catch (err) {
                            console.warn('cant get user', err);
                        }
                    }
                    let accountManagers = [];
                    const userIds = [];
                    if (partner != null && partner.users != null && partner.users.length > 0) {
                        partner.users.forEach((u) => userIds.push(u.userId));
                    }
                    else if (partner == null) {
                        const toUser = [];
                        const roles = ReadModels.get('supportroles');
                        const userRoles = await roles.get({ filter: `deleted ne true` });
                        for (const user of userRoles) {
                            if (!toUser.includes(user.userId)) {
                                toUser.push(user.userId);
                                const u = await client.user.getById(user.userId);
                                accountManagers.push(u);
                            }
                        }
                    }
                    if (userIds.length > 0) {
                        accountManagers = await client.user.get({ filter: `id in ${JSON.stringify(userIds)}`, orderby: 'lastname' });
                    }
                    let payment = new CompanyPaymentEntity({ id: data.id });
                    try {
                        payment = await client.companyPayment.getById(data.id);
                    }
                    catch (e) {
                        console.debug('cant get upmesh Partner settings', e);
                    }
                    if (companySettings == null)
                        return;
                    companyUsers = await client.companyMember.get({
                        filter: `companyId eq '${company.id}' and deleted ne true`,
                    });
                    for (const member of companyUsers) {
                        if (member.userId != null && member.userId.length > 0) {
                            const userProfile = await client.user.getById(member.userId);
                            users.push({ member, userProfile });
                        }
                        else {
                            users.push({ member });
                        }
                    }
                    const cs = await client.companySettings.getById(company.id);
                    for (let i = 0; i < this.modules.length; i += 1) {
                        if (cs.hasModule(this.modules[i].id) === true) {
                            activeModules.push(this.modules[i]);
                        }
                    }
                    this.setState({
                        users,
                        companySettings,
                        company,
                        partner,
                        partners,
                        accountManager,
                        accountManagers,
                        activeModules,
                        payment,
                    });
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.onChange = (field) => (value) => {
            this.currentUserAddData[field] = value;
        };
        this.onChangeRole = (v) => {
            this.setState({ selectedRoleIndex: v.index });
        };
        this.removeUser = (companyUserId, companyId) => (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveUser'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeUserNow(companyUserId, companyId)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.changeRole = (user) => (_e) => {
            Menu.instance?.close();
            const selectedRoleIndex = this.getRoleIndexFor(user);
            this.setState({ changeRoleFor: user, selectedRoleIndex, addUser: false });
        };
        this.removeUserNow = (companyUserId, companyId, force = false) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                this.removeUserNowFinal(companyUserId, companyId, force)
                    .then(() => {
                    LoadingEvents.instance.stopLoading();
                })
                    .catch((err) => {
                    LoadingEvents.instance.stopLoading();
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            });
        };
        this.removeUserNowFinal = async (companyUserId, companyId, force = false) => {
            try {
                const remove = new RemoveUserFromCompany({ companyId, force }, companyUserId, ConnectionHandler.getConnection().token);
                await remove.execute(ConnectionHandler.getStore());
            }
            catch (e) {
                if (e['messageCode'] === 'cantRemoveUserHeIsInvolvedInEnterpriseProjects') {
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjects'),
                        text: 'Soll die Änderungen erzwungen werden? Dann wird dem Benutzer in den ensprechenden Projekten die' +
                            ' Rolle "Nachunternehmer" anstatt "Projektleiter" zugewiesen!',
                        buttons: [
                            <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                            <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeUserNow(companyUserId, companyId, true)} backgroundColor={ThemeManager.style.brandDanger}/>,
                        ],
                    });
                }
                else {
                    throw e;
                }
            }
        };
        this.openPartner = (partner) => {
            Routing.instance.openDialog('partner', { id: partner.id })(null);
        };
        this.changePartner = (_index, value) => {
            const { company } = this.state;
            if (company != null) {
                const c = new ChangeCompany({ partnerId: value.data }, company.id, ConnectionHandler.serverConnection.token);
                c.execute(ConnectionHandler.getStore())
                    .then(() => {
                    const c = new ChangeAccountManager({ accountManager: '' }, company.id, ConnectionHandler.serverConnection.token);
                    return c.execute(ConnectionHandler.getStore());
                })
                    .catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.changeSalesPerson = (_index, value) => {
            const { companySettings } = this.state;
            if (companySettings != null) {
                const c = new ChangeAccountManager({ accountManager: value.data }, companySettings.id, ConnectionHandler.serverConnection.token);
                c.execute(ConnectionHandler.getStore()).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.onChangedModules = async (chips) => {
            const { activeModules, company } = this.state;
            if (company == null)
                return;
            for (let i = 0; i < this.modules.length; i += 1) {
                const wasActive = activeModules.findIndex((activeModule) => this.modules[i].id === activeModule.id) >= 0;
                const isActivated = chips.findIndex((chip) => this.modules[i].id === chip.id) >= 0;
                if (wasActive && !isActivated) {
                    const data = { module: this.modules[i].id };
                    const removeModule = new RemoveModuleFromCompany(data, company.id, CurrentUser.token);
                    await removeModule.execute(ConnectionHandler.getStore());
                }
                else if (!wasActive && isActivated && this.modules[i].id) {
                    const data = { module: this.modules[i].id };
                    const newModule = new AddModuleToCompany(data, company.id, CurrentUser.token);
                    await newModule.execute(ConnectionHandler.getStore());
                }
                this.setState({ activeModules: chips });
            }
        };
        this.openUser = (userId) => (e) => {
            if (userId != null && userId.length > 0)
                Routing.instance.openDialog('user', { id: userId })(e);
        };
        this.openAdd = () => () => {
            this.currentUserAddData = { email: '', firstname: '', lastname: '' };
            this.setState({
                addUser: true,
                changeRoleFor: undefined,
                selectedRoleIndex: 0,
            });
        };
        this.generateExamples = async () => {
            return [
                {
                    firstname: 'Mitarbeiter Vorname 01',
                    lastname: 'Mitarbeiter Nachname 01',
                    role: I18n.m.getMessage('companyRoleAdmin'),
                    email: 'user1@email.de',
                    payroll: 'j',
                    staffNumber: '01',
                    phone: '123456',
                },
                {
                    firstname: 'Mitarbeiter Vorname 02',
                    lastname: 'Mitarbeiter Nachname 02',
                    role: I18n.m.getMessage('companyRoleManager'),
                    email: 'user2@email.de',
                    payroll: 'n',
                    staffNumber: '02',
                    phone: '123456',
                },
                {
                    firstname: 'Mitarbeiter Vorname 03',
                    lastname: 'Mitarbeiter Nachname 03',
                    role: I18n.m.getMessage('companyRoleUser'),
                    email: 'user3@email.de',
                    payroll: 'j',
                    staffNumber: '03',
                    phone: '123456',
                },
                {
                    firstname: 'Mitarbeiter Vorname 04',
                    lastname: 'Mitarbeiter Nachname 04',
                    role: I18n.m.getMessage('companyRoleTimetracker'),
                    email: 'user4@email.de',
                    payroll: 'j',
                    staffNumber: '04',
                    phone: '123456',
                },
                {
                    firstname: 'Mitarbeiter Vorname 05',
                    lastname: 'Mitarbeiter Nachname 05',
                    role: I18n.m.getMessage('companyRoleInactive'),
                    email: 'user5@email.de',
                    payroll: 'j',
                    staffNumber: '05',
                    phone: '123456',
                },
            ];
        };
        this.saveSingle = async (record) => {
            const { company } = this.state;
            if (company == null) {
                return;
            }
            let userRole;
            switch (record.role) {
                case I18n.m.getMessage('companyRoleAdmin'):
                    userRole = 'admin';
                    break;
                case I18n.m.getMessage('companyRoleManager'):
                    userRole = 'user';
                    break;
                case I18n.m.getMessage('companyRoleUser'):
                    userRole = 'collaborator';
                    break;
                case I18n.m.getMessage('companyRoleTimetracker'):
                    userRole = 'timeTracker';
                    break;
                default:
                    userRole = 'inactive';
            }
            let timeTrackingFor;
            switch (record.timeTrackingFor) {
                case I18n.m.getMessage('companyUserCanTimeTrackForAll'):
                    timeTrackingFor = 'all';
                    break;
                case I18n.m.getMessage('companyUserCanTimeTrackForGroup'):
                    timeTrackingFor = 'group';
                    break;
                case I18n.m.getMessage('companyUserCanTimeTrackForNobody'):
                    timeTrackingFor = 'nobody';
                    break;
                default:
                    timeTrackingFor = 'self';
            }
            let timeTrackingFrom;
            switch (record.timeTrackingFrom) {
                case I18n.m.getMessage('companyUserCanTimeTrackFromAll'):
                    timeTrackingFrom = 'all';
                    break;
                case I18n.m.getMessage('companyUserCanTimeTrackFromGroup'):
                    timeTrackingFrom = 'group';
                    break;
                default:
                    timeTrackingFrom = 'self';
            }
            const staffNumber = typeof record.staffNumber === 'string'
                ? record.staffNumber
                : record.staffNumber && typeof record.staffNumber.toString === 'function'
                    ? record.staffNumber.toString()
                    : '';
            const data = {
                companyId: company.id,
                email: record.email,
                role: userRole,
                firstname: record.firstname,
                lastname: record.lastname,
                payroll: record.payroll === 'j',
                staffNumber,
                timeTrackingFor,
                timeTrackingFrom,
            };
            const createCommand = new AddNewUserToCompany(data, uuid.v1(), CurrentUser.token);
            try {
                await createCommand.execute(ConnectionHandler.getStore());
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        this.saveFunction = async (records) => {
            records.forEach((record) => {
                this.saveSingle(record).catch((err) => {
                    console.error('save error', err);
                });
            });
        };
        this.openAddMultiple = () => () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: true,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} singleSaveFunction={this.saveSingle} generateExampleRecords={this.generateExamples} columns={[
                        {
                            title: I18n.m.getMessage('firstname'),
                            keyInData: 'firstname',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('lastname'),
                            keyInData: 'lastname',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('role'),
                            keyInData: 'role',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('email'),
                            keyInData: 'email',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('payroll'),
                            keyInData: 'payroll',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('staffNumber'),
                            keyInData: 'staffNumber',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('phone'),
                            keyInData: 'phone',
                            style: { width: 200 },
                        },
                    ]}/>),
            });
        };
        this.changeUserRole = (force) => () => {
            const { selectedRoleIndex, changeRoleFor } = this.state;
            Alert.instance?.close();
            if (changeRoleFor != null) {
                const r = new ChangeCompanyUserRole({
                    companyId: changeRoleFor.companyId,
                    role: this.getRoleList()[selectedRoleIndex].data,
                    force,
                }, changeRoleFor.id, CurrentUser.token);
                LoadingEvents.instance.startLoading();
                r.execute(ConnectionHandler.getStore())
                    .then(() => {
                    LoadingEvents.instance.stopLoading();
                    this.setState({ changeRoleFor: undefined, selectedRoleIndex: 0 }, () => {
                        this.init();
                    });
                })
                    .catch((e) => {
                    LoadingEvents.instance.stopLoading();
                    if (e['messageCode'] === 'cantRemoveUserHeIsInvolvedInEnterpriseProjects') {
                        Routing.instance.alert.post({
                            title: I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjects'),
                            text: 'Soll die Änderungen erzwungen werden? Dann wird dem Benutzer in den ensprechenden Projekten die' +
                                ' Rolle "Nachunternehmer" anstatt "Projektleiter" zugewiesen!',
                            buttons: [
                                <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="later" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                <ContainedButton key="now" title="Erzwingen" onPress={this.changeUserRole(true)}/>,
                            ],
                        });
                    }
                    else {
                        console.debug(e);
                        Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                    }
                });
            }
        };
        this.openUserMenu = (user) => (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: 'Rolle ändern',
                            onPress: this.changeRole(user),
                            thumbnail: {
                                thumbnail: <Icon icon="pencil-outline" toolTip=""/>,
                                width: 24,
                            },
                        },
                        {
                            text: 'Benutzer entfernen',
                            onPress: this.removeUser(user.id, user.companyId),
                            thumbnail: {
                                thumbnail: <Icon icon="delete-outline" toolTip=""/>,
                                width: 24,
                            },
                        },
                    ],
                });
            });
        };
        this.saveAddUser = () => {
            this.submitAddUser(this.currentUserAddData.email).catch((err) => console.debug(err));
            this.setState({ addUser: false });
        };
        this.saveAddNewUser = async () => {
            LoadingEvents.instance.startLoading();
            const { company, selectedRoleIndex } = this.state;
            if (company == null)
                return;
            try {
                const data = {
                    companyId: company.id,
                    email: this.currentUserAddData.email,
                    role: this.getRoleList()[selectedRoleIndex].data,
                    firstname: this.currentUserAddData.firstname,
                    lastname: this.currentUserAddData.lastname,
                };
                const add = new AddNewUserToCompany(data, uuid.v1(), ConnectionHandler.getConnection().token);
                await add.execute(ConnectionHandler.getStore());
                this.setState({ addNewUser: false });
            }
            catch (e) {
                console.error(e);
                const text = e['errorMessage'] != null ? e['errorMessage'] : JSON.stringify(e);
                Routing.instance.alert.post({ text });
            }
            LoadingEvents.instance.stopLoading();
        };
        this.submitAddUser = async (email) => {
            LoadingEvents.instance.startLoading();
            const { company, selectedRoleIndex } = this.state;
            if (company == null)
                return;
            try {
                const data = {
                    companyId: company.id,
                    email,
                    role: this.getRoleList()[selectedRoleIndex].data,
                };
                const add = new AddUserToCompany(data, uuid.v1(), ConnectionHandler.getConnection().token);
                await add.execute(ConnectionHandler.getStore());
            }
            catch (e) {
                console.error(e);
                const { company } = this.state;
                if (e['messageCode'] === 'userNotFound') {
                    this.setState({ addNewUser: true });
                }
                else if (e['messageCode'] === 'userAlreadyAssignedToOneCompany' &&
                    e['messageData'] &&
                    e['messageData']['companyId'] !== company?.id) {
                    const companyId = e['messageData']['companyId'];
                    const companyUserId = e['messageData']['memberId'];
                    const userName = e['messageData']['userName'];
                    const companyName = e['messageData']['companyName'];
                    Routing.instance.alert.post({
                        text: `Der Benutzer “${userName}” ist bereits in der Firma “${companyName}”. Soll er in die Firma “${company?.company}” wechseln?`,
                        buttons: [
                            <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                            <ContainedButton key="yes" title="wechseln" onPress={() => {
                                    Alert.instance?.close(() => {
                                        LoadingEvents.instance.startLoading();
                                        this.removeUserNowFinal(companyUserId, companyId, true)
                                            .then(() => {
                                            setTimeout(() => {
                                                this.submitAddUser(email).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                                                LoadingEvents.instance.stopLoading();
                                            }, 5000);
                                        })
                                            .catch((err) => {
                                            LoadingEvents.instance.stopLoading();
                                            DefaultErrorHandler.showDefaultErrorAlert(err);
                                        });
                                    });
                                }} backgroundColor={ThemeManager.style.brandDanger}/>,
                        ],
                    });
                }
                else {
                    const text = e['errorMessage'] != null ? e['errorMessage'] : JSON.stringify(e);
                    Routing.instance.alert.post({ text });
                }
            }
            LoadingEvents.instance.stopLoading();
        };
        this.state = {
            currentTab: props.data.tab != null ? Number.parseInt(props.data.tab, 10) : 0,
            addUser: false,
            addNewUser: false,
            company: props.company,
            activeModules: [],
            users: [],
            selectedRoleIndex: 0,
        };
    }
    componentDidMount() {
        this.listenerId = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ company: e.entity }, this.init);
            },
            readModelName: 'Company',
            entityId: this.props.data.id,
        });
        this.listenerId2 = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                let hasId = false;
                en.entities.forEach((e) => {
                    if (e.entity.companyId === this.props.data.id)
                        hasId = true;
                });
                if (hasId) {
                    this.init();
                }
            },
            readModelName: 'CompanyMember',
        });
        this.listenerId4 = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ payment: e.entity }, this.init);
            },
            readModelName: 'CompanyPayment',
            entityId: this.props.data.id,
        });
        this.listenerId5 = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ payment: e.entity }, this.init);
            },
            readModelName: 'CompanySettings',
            entityId: this.props.data.id,
        });
        this.init();
    }
    componentWillUnmount() {
        if (this.listenerId) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('Company', this.listenerId);
        }
        if (this.listenerId2) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('CompanyMember', this.listenerId2);
        }
        if (this.listenerId4) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('CompanyPayment', this.listenerId4);
        }
        if (this.listenerId5) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('CompanySettings', this.listenerId5);
        }
    }
    renderPayment() {
        const { payment } = this.state;
        if (payment == null)
            return null;
        return (<View style={CompanyDialog.styles.section}>
        <View style={CompanyDialog.styles.head}>
          <MaterialText type={MaterialTextTypes.H6}>Preise</MaterialText>
        </View>
        <View style={CompanyDialog.styles.row} key="price">
          <MaterialText centeredBox>Projektleiter</MaterialText>
          <MaterialText centeredBox>{this.formatPrice(payment.price)}</MaterialText>
        </View>
        <View style={{ ...CompanyDialog.styles.row, backgroundColor: '#FFFFFF' }} key="priceCollaborator">
          <MaterialText centeredBox>Mitarbeiter</MaterialText>
          <MaterialText centeredBox>{this.formatPrice(payment.priceCollaborator)}</MaterialText>
        </View>
        <View style={CompanyDialog.styles.row} key="priceTimeTracking">
          <MaterialText centeredBox>Zeiterfasser</MaterialText>
          <MaterialText centeredBox>{this.formatPrice(payment.priceTimeTracking)}</MaterialText>
        </View>
      </View>);
    }
    render() {
        const { company, currentTab, payment } = this.state;
        if (company == null || payment == null)
            return <Spinner />;
        return (<View style={{
                ...ThemeManager.style.absoluteStyle,
            }}>
        <View style={{
                width: '100%',
                padding: 0,
                paddingHorizontal: ThemeManager.style.getScreenRelativePixelSize(16),
            }}/>
        <TabBar contentHeight={0} tabs={[
                { title: company.company },
                { title: I18n.m.getMessage('collaborator') },
                { title: 'Lizenzen' },
                { title: I18n.m.getMessage('projects') },
                { title: I18n.m.getMessage('payments') },
                { title: I18n.m.getMessage('notes') },
            ]} width={ResizeEvent.current.windowWidth - 96} onChangedSelected={this.onChangeTab} startSelected={currentTab}/>
        {currentTab === 0 && (<ScrollView style={{ width: '100%', height: '100%' }}>
            <View style={{
                    width: '100%',
                    padding: ThemeManager.style.getScreenRelativePixelSize(16),
                }}>
              {currentTab === 0 && (<View style={{ width: '100%' }}>
                  <MaterialText type={MaterialTextTypes.Caption}>Firma</MaterialText>
                  <MaterialText type={MaterialTextTypes.H6}>{company.company}</MaterialText>
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>Id</MaterialText>
                  <MaterialText>{company.id}</MaterialText>
                  {ConnectionHandler.isPartner && company.partnerCustomerNumber == null ? null : (<View style={{ paddingTop: 16 }}>
                      <MaterialText type={MaterialTextTypes.Caption}>Kundennummer</MaterialText>
                      <MaterialText>{company.partnerCustomerNumber}</MaterialText>
                    </View>)}
                  {!ConnectionHandler.isPartner && company.customerNumber == null ? null : (<View style={{ paddingTop: 16 }}>
                      <MaterialText type={MaterialTextTypes.Caption}>Kundennummer (upmesh)</MaterialText>
                      <MaterialText>{company.customerNumber}</MaterialText>
                    </View>)}
                  {!ConnectionHandler.isPartner && company.billwerkCustomerNumber == null ? null : (<View style={{ paddingTop: 16 }}>
                      <MaterialText type={MaterialTextTypes.Caption}>Billwerk Kundennummer</MaterialText>
                      <MaterialText>{company.billwerkCustomerNumber}</MaterialText>
                    </View>)}
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>Adresse</MaterialText>
                  <MaterialText>{`${company.street} ${company.streetNr}`}</MaterialText>
                  <MaterialText>{`${company.zip} ${company.city}`}</MaterialText>
                  <MaterialText>{`${company.country}`}</MaterialText>
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>Kontaktperson</MaterialText>
                  <MaterialText>{company.contactName != null ? company.contactName : '--'}</MaterialText>
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>E-Mail</MaterialText>
                  <MaterialText>{`${company.email}`}</MaterialText>
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>Telefon</MaterialText>
                  <MaterialText>{`${company.phone}`}</MaterialText>
                  <View style={{ height: 16 }}/>
                  <MaterialText type={MaterialTextTypes.Caption}>Beschreibung</MaterialText>
                  <MaterialText>{`${company.description != null ? company.description : ''}`}</MaterialText>
                  {this.renderPartner()}
                  {this.renderSalesPerson()}
                </View>)}
              <View style={{ height: 16 }}/>
              {currentTab === 0 && this.renderModules()}
            </View>
            {currentTab === 0 && this.renderPayment()}
            <View style={{ height: 16 }}/>
            <MaterialText type={MaterialTextTypes.Caption}>Firmen Zugriff</MaterialText>,
            <View style={{ flexDirection: 'row' }}>
              <ContainedButton title="Firmenzugriff neu erstellen" onPress={this.updateCompanyAccess} backgroundColor={ThemeManager.style.brandPrimary}/>
            </View>
          </ScrollView>)}
        {currentTab === 1 && this.renderUsers()}
        {currentTab === 2 && (<CompanySubscriptions company={company} companyPayment={payment} height={ResizeEvent.current.windowHeight - 68}/>)}
        {currentTab === 3 && <CompanyProjects company={company} height={ResizeEvent.current.windowHeight - 68}/>}
        {currentTab === 4 && <CompanyPayments company={company} height={ResizeEvent.current.windowHeight - 68}/>}
        {currentTab === 5 && <CompanyNotes company={company} height={ResizeEvent.current.windowHeight - 68}/>}
        <View style={{ position: 'absolute', flexDirection: 'row', top: 8, right: 8 }}>
          <Icon icon="pencil-outline" onPress={this.edit} toolTip=""/>
          <View style={{ width: 8 }}/>
          <Icon icon="close" onPress={this.close} toolTip=""/>
        </View>
      </View>);
    }
    formatPrice(value) {
        const e = typeof value === 'string' ? Number.parseFloat(value) : value;
        try {
            if (typeof e === 'number')
                return `${e.toFixed(2)} €`;
            return `${e} €`;
        }
        catch (err) {
            return `${err.toString()} €`;
        }
    }
    getRoleIndexFor(user) {
        let s = user.role === 'admin' ? 0 : 2;
        if (user.role === 'user')
            s = 1;
        if (user.role === 'timeTracker')
            s = 3;
        return s;
    }
    getPartnerList() {
        const partnerList = [
            { title: 'upmesh', data: null },
        ];
        const { partners } = this.state;
        if (partners && partners.length > 0) {
            partners.forEach((p) => {
                partnerList.push({ title: p.company, data: p.id });
            });
        }
        return partnerList;
    }
    getSalesPersonList() {
        const list = [{ title: 'Keiner', data: '' }];
        const { accountManagers } = this.state;
        if (accountManagers != null && accountManagers.length > 0) {
            accountManagers.forEach((p) => {
                list.push({ title: p.getFullName(), data: p.id });
            });
        }
        return list;
    }
    renderSalesPerson() {
        const { partner, accountManager } = this.state;
        const accountManagersList = this.getSalesPersonList();
        const currentPartnerSelected = accountManager == null ? 0 : accountManagersList.findIndex((p) => p.data === accountManager.id);
        return (<View style={{ marginTop: 16 }}>
        <MaterialText type={MaterialTextTypes.Caption} onPress={accountManager == null || partner == null ? undefined : () => this.openPartner(partner)}>
          Account Manager:
        </MaterialText>
        <Picker list={accountManagersList} selectedIndex={currentPartnerSelected > 0 ? currentPartnerSelected : 0} onChange={this.changeSalesPerson}/>
      </View>);
    }
    renderPartner() {
        const { partner, partners } = this.state;
        if (!ConnectionHandler.isPartner && partners != null && partners.length > 0) {
            const partnerList = this.getPartnerList();
            const currentPartnerSelected = partner == null ? 0 : partnerList.findIndex((p) => p.data === partner.id);
            return (<View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption} onPress={partner == null ? undefined : () => this.openPartner(partner)}>
            Kunde über Partner:
          </MaterialText>
          <Picker list={partnerList} selectedIndex={currentPartnerSelected} onChange={this.changePartner}/>
        </View>);
        }
        if (partner == null)
            return null;
        return (<View style={{ marginTop: 16 }}>
        <MaterialText type={MaterialTextTypes.Caption}>Kunde über Partner:</MaterialText>
        <MaterialText onPress={() => this.openPartner(partner)}>{`${partner.company}`}</MaterialText>
      </View>);
    }
    renderModules() {
        const { activeModules } = this.state;
        return (<ChipGroup label="Modules" key={`cc_${JSON.stringify(activeModules)}`} chips={activeModules} availableChips={this.modules} onChanged={(c) => {
                this.onChangedModules(c).catch(DefaultErrorHandler.showDefaultErrorAlert);
            }}/>);
    }
    getRoleList() {
        return [
            {
                title: `${I18n.m.getMessage('companyRoleAdmin')} (${I18n.m.getMessage('companyUserLicenseFull')})`,
                data: 'admin',
            },
            {
                title: `${I18n.m.getMessage('companyRoleManager')} (${I18n.m.getMessage('companyUserLicenseFull')})`,
                data: 'user',
            },
            {
                title: `${I18n.m.getMessage('companyRoleUser')} (${I18n.m.getMessage('companyUserLicenseCollaborator')})`,
                data: 'collaborator',
            },
            {
                title: `${I18n.m.getMessage('companyRoleTimetracker')} (${I18n.m.getMessage('companyUserLicenseTimeTracking')})`,
                data: 'timeTracker',
            },
            {
                title: `${I18n.m.getMessage('companyRoleInactive')}`,
                data: 'inactive',
            },
        ];
    }
    renderUsers() {
        const { company, companySettings, addUser, addNewUser, users, changeRoleFor, selectedRoleIndex } = this.state;
        if (company == null || companySettings == null)
            return <View />;
        const lines = [];
        if (users == null || users.length === 0) {
            lines.push(<View style={CompanyDialog.styles.row} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const user of users) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                let text = user.userProfile != null
                    ? user.userProfile.getFullName()
                    : `${user.member.firstName} ${user.member.lastName}`;
                if (user.member.licenseFree)
                    text += ' (Lizenzfrei)';
                let text2 = 'Projektleiter';
                if (user.member.role === 'admin')
                    text2 = 'Firmenadmin';
                else if (user.member.role === 'timeTracker')
                    text2 = 'Zeiterfasser';
                else if (user.member.role === 'collaborator')
                    text2 = 'Mitarbeiter';
                else if (user.member.role === 'inactive')
                    text2 = 'inaktiv';
                lines.push(<ListItem backgroundColor={backgroundColor} title={text} height={48} secondTextLine={text2} key={`${user.member.id}_${user.member.lastModifiedAt}`} onPress={this.openUser(user.member.userId)} iconRight={{
                        icon: 'dots-vertical',
                        toolTip: '',
                        onPress: this.openUserMenu(user.member),
                    }}/>);
                if (changeRoleFor != null && changeRoleFor.id === user.member.id) {
                    lines.push(<View style={{ backgroundColor: 'rgb(202,202,202)', padding: 16 }} key="changeRole">
              <FormInputPicker labelText={I18n.m.getMessage('companyRole')} list={this.getRoleList()} selectedIndex={selectedRoleIndex} onChange={this.onChangeRole}/>
              <View style={{ height: 24 }}/>
              <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={() => this.setState({ changeRoleFor: undefined })}/>
                <ContainedButton key="yes" title="ändern" onPress={this.changeUserRole(false)}/>
              </View>
            </View>);
                }
            }
        }
        if (addNewUser) {
            lines.push(<View style={{ backgroundColor: 'rgb(202,202,202)', padding: 16 }} key="add2">
          <MaterialText>
            {`Der Benutzer mit der E-Mail Adresse "${this.currentUserAddData.email}" wurde nicht gefunden, soll der Account angelegt werden?`}
          </MaterialText>
          <FormInputFilled labelText="Vorname" onChange={this.onChange('firstname')}/>
          <FormInputFilled labelText="Nachname" onChange={this.onChange('lastname')}/>
          <View style={{ height: 24 }}/>
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
            <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={this.closeAdd}/>
            <ContainedButton key="yes" title="Account anlegen" onPress={() => {
                    this.saveAddNewUser().catch(DefaultErrorHandler.showDefaultErrorAlert);
                }}/>
          </View>
        </View>);
        }
        else if (addUser) {
            lines.push(<View style={{ backgroundColor: 'rgb(202,202,202)', padding: 16 }} key="add2">
          <FormInputFilled labelText="E-Mail Adresse" onChange={this.onChange('email')}/>
          <View style={{ height: 16 }}/>
          <FormInputPicker labelText={I18n.m.getMessage('companyRole')} list={this.getRoleList()} onChange={this.onChangeRole} selectedIndex={selectedRoleIndex}/>
          <View style={{ height: 24 }}/>
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
            <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={this.closeAdd}/>
            <ContainedButton key="yes" title="hinzufügen" onPress={this.saveAddUser}/>
          </View>
        </View>);
        }
        else {
            lines.push(<View style={{ backgroundColor: ThemeManager.style.brandPrimary, alignItems: 'center' }} key="add">
          <Icon icon="plus" color="#FFFFFF" toolTip="Benutzer hinzufügen" onPress={this.openAdd()}/>
          <Icon icon="microsoft-excel" color="#FFFFFF" toolTip="Excel Import von Mitarbeitern" onPress={this.openAddMultiple()}/>
        </View>);
        }
        const fullUser = users.filter((a) => (a.member.role === 'admin' || a.member.role === 'user') && !a.member.licenseFree).length;
        const managerLicenses = companySettings.managerLicenses == null ? 0 : companySettings.managerLicenses;
        const timeTrackers = users.filter((a) => a.member.role === 'timeTracker' && !a.member.licenseFree).length;
        const timeTrackersLicences = companySettings.timeTrackingLicenses == null ? 0 : companySettings.timeTrackingLicenses;
        const collaborators = users.filter((a) => a.member.role === 'collaborator' && !a.member.licenseFree).length;
        const collaboratorLicenses = companySettings.collaboratorLicenses == null ? 0 : companySettings.collaboratorLicenses;
        return (<View style={CompanyDialog.styles.section}>
        <View style={CompanyDialog.styles.head}>
          <MaterialText type={MaterialTextTypes.H6}>
            
            Benutzer: {fullUser}/{managerLicenses} Projekleiter, {collaborators}/{collaboratorLicenses} Mitarbeiter,{' '}
            {timeTrackers}/{timeTrackersLicences} Zeiterfasser
          </MaterialText>
        </View>
        <ScrollView style={{ width: '100%', height: ResizeEvent.current.windowHeight - 116 }} contentContainerStyle={{ paddingBottom: 128 }}>
          {lines}
        </ScrollView>
      </View>);
    }
}
CompanyDialog.styles = StyleSheet.create({
    section: {
        marginTop: 24,
        padding: 16,
    },
    head: {
        paddingHorizontal: 8,
    },
    row: {
        width: '100%',
        backgroundColor: '#EEEEEE',
        flexDirection: 'row',
        minHeight: 36,
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
});
