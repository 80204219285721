import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ContentHeaderEventHandler, } from 'materialTheme/src/theme/components/header/ContentHeaderEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ChangePayment } from 'upmesh-support-core/src/commands/ChangePayment';
import { DeletePayment } from 'upmesh-support-core/src/commands/DeletePayment';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { openpaymentDialog } from './PaymentAddOrChangeDialog';
import { PaymentExtended } from './PaymentExtended';
import { PaymentTableSum } from './PaymentTableSum';
export function PaymentTable(props) {
    const [activeSegment, setActiveSegment] = useState(0);
    const [payments, setPayments] = useState([]);
    const [activeMultiselect, setActiveMultiselect] = useState(false);
    const [selectedIDs, setSelectedIDs] = useState(new Set());
    const t = new Date();
    const from = new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0, 0);
    const to = new Date(t.getFullYear(), t.getMonth() + 1, 1, 0, 0, 0, -1);
    const [fromList, setFromList] = useState([]);
    const [fromSelectedIndex, setFromSelectedIndex] = useState(0);
    const [currentInterval, setCurrentInterval] = useState({ from, to });
    const createColumn = () => {
        const c = [
            { title: 'Fällig', dataType: 'Date', keyInData: 'due', style: { minWidth: 200 } },
        ];
        c.push({
            title: 'Artikelnummer',
            keyInData: 'itemNumber',
            style: { width: 250 },
            dataType: 'string',
            minWidth: 180,
            cellRenderer: (item, column, _index) => {
                return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText fixedWidth="100%" textAlign="left">{`${item.itemNumber ? `${item.itemNumber}` : '-'}`}</MaterialText>
          </View>);
            },
        });
        c.push({
            title: 'EinzelPreis',
            keyInData: 'unitPrice',
            style: { width: 250 },
            dataType: 'number',
            minWidth: 180,
            cellRenderer: (item, column, _index) => {
                return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText fixedWidth="100%" textAlign="right">{`${item.unitPrice ? item.unitPrice.toFixed(2) : '0'} €`}</MaterialText>
            </View>);
            },
        }, {
            title: 'Anzahl',
            keyInData: 'amount',
            style: { width: 250 },
            dataType: 'number',
            minWidth: 180,
        }, {
            title: 'Summe',
            keyInData: 'totalPrice',
            style: { width: 250 },
            dataType: 'number',
            minWidth: 180,
            cellRenderer: (item, column, _index) => {
                return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText fixedWidth="100%" textAlign="right">{`${item.totalPrice ? item.totalPrice.toFixed(2) : '0'} €`}</MaterialText>
            </View>);
            },
        }, { title: 'Beschreibung', dataType: 'string', keyInData: 'description', style: { minWidth: 200 } }, {
            title: 'Type',
            keyInData: 'subscriptionId',
            style: { width: 250 },
            dataType: 'string',
            minWidth: 180,
            cellRenderer: (item, column, _index) => {
                return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText fixedWidth="100%" textAlign="left">{`${item.subscriptionId ? `Abo ${item.subscriptionId}` : 'Einmalzahlung'}`}</MaterialText>
            </View>);
            },
        });
        c.push({ title: 'Erstellt', dataType: 'Date', keyInData: 'createdAt', style: { minWidth: 200 } }, { title: 'In Rechnung gestellt', dataType: 'Date', keyInData: 'billedOn', style: { minWidth: 200 } }, { title: 'Bezahlt', dataType: 'Date', keyInData: 'paidOn', style: { minWidth: 200 } });
        if (props.hideFields == null || !props.hideFields.includes('toName'))
            c.unshift({
                title: 'Forderung an',
                keyInData: 'toName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            });
        if (props.hideFields == null || !props.hideFields.includes('fromName'))
            c.unshift({
                title: 'Von',
                keyInData: 'fromName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            });
        return c;
    };
    const columns = createColumn();
    const getFromList = async () => {
        const { isPartner, partnerId } = ConnectionHandler;
        const { onlyFrom } = props;
        const l = onlyFrom == null || onlyFrom.includes('') ? [{ title: 'upmesh', data: '' }] : [];
        if (!partnerId) {
            const partner = await ConnectionHandler.getClient()?.upmeshPartner.get({ orderby: 'company ASC' });
            partner?.forEach((c) => {
                if (onlyFrom == null)
                    l.push({ title: `${c.company}${!isPartner && `(Partner)`}`, data: c.id });
                else if (onlyFrom.includes(c.id))
                    l.unshift({ title: `${c.company}${!isPartner && `(Partner)`}`, data: c.id });
            });
        }
        else if (onlyFrom == null || onlyFrom.includes(partnerId))
            l.unshift({ title: 'Eigene', data: ConnectionHandler.partnerId });
        setFromSelectedIndex(0);
        setFromList(l);
    };
    const updateData = () => {
        const client = ConnectionHandler.getClient();
        if (client == null)
            return;
        if (fromList.length <= fromSelectedIndex)
            return;
        const fromId = fromList[fromSelectedIndex].data;
        const fFrom = fromId.length === 0 ? `from eq null and ` : `from eq '${fromId}' and `;
        const f = activeSegment === 1
            ? `paidOn eq null and billedOn ne null`
            : activeSegment === 2
                ? `paidOn ne null`
                : `paidOn eq null and billedOn eq null`;
        const fInterval = currentInterval == null
            ? ''
            : `due gt ${currentInterval.from.toISOString()} and due lt ${currentInterval.to.toISOString()} and `;
        const filter = `${fFrom}${fInterval}deleted ne true and ${f}`;
        client.payments
            .get({
            select: [
                'id',
                'subscriptionId',
                'due',
                'from',
                'paidOn',
                'createdAt',
                'billedOn',
                'to',
                'description',
                'unitPrice',
                'amount',
                'totalPrice',
                'itemNumber',
            ],
            filter: props.preFilter ? `(${props.preFilter}) and ${filter}` : filter,
        })
            .then((p) => {
            const promises = [];
            p.forEach((payment) => {
                promises.push(new Promise((resolve) => {
                    PaymentExtended.paymentToExtended(payment)
                        .then((p) => resolve(p))
                        .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }));
            });
            return Promise.all(promises);
        })
            .then((p2) => {
            setPayments(p2);
            if (props.onDataUpdated)
                props.onDataUpdated(p2);
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    useEffect(() => {
        updateData();
    }, [activeSegment, props.preFilter, currentInterval, fromSelectedIndex, fromList]);
    useEffect(() => {
        getFromList().catch((err) => console.debug('error', err));
    }, []);
    useEffect(() => {
        const l = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                let hasId = props.forTo == null;
                if (!hasId) {
                    en.entities.forEach((e) => {
                        if (e.entity.to.id === props.forTo?.id)
                            hasId = true;
                    });
                }
                console.log('HAS id?', hasId, en.entities);
                if (hasId) {
                    updateData();
                }
            },
            readModelName: 'Payments',
        });
        return () => {
            if (l)
                ConnectionHandler.getClient()?.eventDispatcher.detach('Payments', l);
        };
    }, []);
    const deletePaymentNow = async (p) => {
        const c = new DeletePayment({}, p.id, ConnectionHandler.getConnection().token);
        await c.execute(ConnectionHandler.getStore());
    };
    const deletePayment = (p) => (_e) => {
        Routing.instance.alert.post({
            text: `Möchten Sie den Eintrag wirklich löschen?`,
            buttons: [
                <ContainedButton key="discard" title={I18n.m.getMessage('cancel')} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => {
                        Alert.instance?.close();
                    }}/>,
                <ContainedButton key="save" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('delete')} onPress={() => {
                        Alert.instance?.close(() => {
                            deletePaymentNow(p).catch((e) => {
                                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                            });
                        });
                    }}/>,
            ],
        });
    };
    const editPayment = (p) => (e) => {
        openpaymentDialog({ payment: p })(e);
    };
    const updatePayment = (p, id) => {
        const c = new ChangePayment(p, id, ConnectionHandler.getConnection()?.token);
        c.execute(ConnectionHandler.getStore()).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    const closeMultiselectHeader = () => {
        setActiveMultiselect(false);
        setSelectedIDs(new Set());
        const data = {
            open: false,
        };
        ContentHeaderEventHandler.statusEvent.post(data);
    };
    const nextStep = (p) => (_e) => {
        Datepicker.open({
            mode: 'date',
            onChange: (d) => {
                if (d == null || !(d instanceof Date))
                    return;
                if (p instanceof Set) {
                    p.forEach((p) => {
                        const f = payments.find((a) => a.id === p);
                        if (f) {
                            if (f.billedOn == null) {
                                updatePayment({ billedOn: new Date(d) }, f.id);
                            }
                            else if (f.paidOn == null) {
                                updatePayment({ paidOn: new Date(d) }, f.id);
                            }
                        }
                    });
                    closeMultiselectHeader();
                }
                else if (p.billedOn == null) {
                    updatePayment({ billedOn: new Date(d) }, p.id);
                }
                else if (p.paidOn == null) {
                    updatePayment({ paidOn: new Date(d) }, p.id);
                }
            },
        });
    };
    useEffect(() => {
        if (!activeMultiselect)
            return;
        const headerHeight = ThemeManager.style.headerHeight + ThemeManager.style.getScreenRelativePixelSize(40);
        const headerContent = (<View style={{
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'space-between',
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: ThemeManager.style.contentPaddingValue,
                width: '100%',
                alignItems: 'center',
            }}>
        <View style={{
                flexDirection: 'row',
                alignItems: 'center',
            }}>
          <Icon toolTip={I18n.m.getMessage('close')} icon="close" color={ThemeManager.style.brandPrimary} onPress={closeMultiselectHeader}/>
          <MaterialText color={ThemeManager.style.brandPrimary} centeredText centeredBox>
            {selectedIDs.size}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon icon="update" toolTip={activeSegment === 0 ? 'In Rechnung stellen' : 'bezahlt'} color={ThemeManager.style.brandPrimary} onPress={nextStep(selectedIDs)} disabled={selectedIDs.size === 0}/>
        </View>
      </View>);
        const data = {
            headerContent,
            open: true,
            headerHeight,
        };
        ContentHeaderEventHandler.statusEvent.post(data);
    }, [activeMultiselect, selectedIDs]);
    const onMultiSelect = (s) => {
        setActiveMultiselect(s.size > 0);
        if (s.size === 0)
            closeMultiselectHeader();
        setSelectedIDs(s);
    };
    return (<View style={{ width: '100%', position: 'relative', height: props.height, overflow: 'visible' }}>
      <View style={{
            height: 64,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 16,
            justifyContent: 'space-between',
        }}>
        <View style={{
            height: 64,
            flexDirection: 'row',
            alignItems: 'center',
        }}>
          <DateRangeInput selectedDate={currentInterval} onChange={(d) => setCurrentInterval(d)}/>
          {fromList.length > 1 && (<View style={{ width: 256, paddingTop: 12 }}>
              <FormInputPicker labelText="Von" selectedIndex={fromSelectedIndex} list={fromList} onChange={(value) => {
                setFromSelectedIndex(value.index);
            }}/>
            </View>)}
        </View>
        {props.showSum && <PaymentTableSum p={payments}/>}
      </View>
      <SegmentedButton multiselect={false} buttons={[{ title: 'offen' }, { title: 'wartend (in Rechnung gestellt)' }, { title: 'bezahlt' }]} singleSelectSelected={activeSegment} onPress={(selected) => {
            setActiveSegment(selected);
        }}/>
      {payments != null && payments.length === 0 ? (<View style={{ padding: 16, width: '100%' }}>
          <MaterialText fixedWidth="100%" textAlign="center">
            Keine Zahlungsdaten vorhanden
          </MaterialText>
        </View>) : (<Table data={payments} tableName={`PaymentTable_${props.tableName}`} actionItemsLength={3} multiselectEnabled onMultiSelect={onMultiSelect} selectedIDs={selectedIDs} actions={(_item) => {
                const a = [
                    {
                        icon: 'pencil-outline',
                        onAction: editPayment,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    { icon: 'delete-outline', onAction: deletePayment, toolTip: I18n.m.getMessage('remove') },
                ];
                if (_item.billedOn == null) {
                    a.unshift({
                        icon: 'update',
                        onAction: nextStep,
                        toolTip: 'in Rechnung gestellt',
                    });
                }
                else if (_item.paidOn == null) {
                    a.unshift({
                        icon: 'update',
                        onAction: nextStep,
                        toolTip: 'bezahlt',
                    });
                }
                return a;
            }} onRowPress={props.onRowPress ? props.onRowPress : undefined} columns={columns} maxHeight={props.height - 120}/>)}
    </View>);
}
