var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableCell = TableCell;
var _react = _interopRequireDefault(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _TableTypes = require("./TableTypes");
var _MaterialText = require("./text/MaterialText");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function TableCell(props) {
  var getDefaultCellTextAlignment = function getDefaultCellTextAlignment(dataType) {
    if (dataType === 'object') {
      return 'center';
    }
    return dataType === 'string' ? 'flex-start' : 'flex-end';
  };
  var _props$column = props.column,
    keyInData = _props$column.keyInData,
    dataType = _props$column.dataType,
    style = _props$column.style,
    cellStyle = _props$column.cellStyle;
  var out = props.column.format != null ? props.column.format(props.item) : props.item[keyInData];
  if (dataType != null && out != null) {
    if (dataType === 'Date') {
      out = _I18n.I18n.m.dateCurrent.localeDateString(props.item[keyInData]);
    } else if (dataType === 'DateWithTime') {
      out = _I18n.I18n.m.dateCurrent.localeDateWithTimeString(props.item[keyInData]);
    } else if (dataType === 'Time') {
      out = _I18n.I18n.m.dateCurrent.getLocalTimeString(props.item[keyInData]);
    }
  }
  if (out == null) out = '';
  var cellAlignment = props.sLayout ? 'flex-end' : getDefaultCellTextAlignment(dataType);
  var onCellPress = props.column.onCellPress;
  if (onCellPress != null) {
    return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
      onPress: function onPress(e) {
        onCellPress(props.item, e);
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [_TableTypes.styles.cellContainer, style, {
          height: props.lineHeight,
          minHeight: props.sLayout ? undefined : 54,
          alignSelf: cellAlignment,
          width: '100%',
          maxWidth: '100%'
        }],
        children: keyInData ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: [_TableTypes.styles.cellContent, {
            justifyContent: cellAlignment
          }, cellStyle],
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            showToolTipOnCutText: true,
            textAlign: cellAlignment === 'flex-end' ? 'right' : undefined,
            ellipsizeMode: "tail",
            numberOfLines: props.sLayout ? undefined : 1,
            fixedWidth: "100%",
            centeredBox: false,
            children: out
          })
        }) : null
      }, `cellRipple-${props.item.id}-${props.index}`)
    }, `cellRipple-${props.item.id}-${props.index}`);
  }
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: [_TableTypes.styles.cellContainer, style, {
      height: props.lineHeight,
      minHeight: props.sLayout ? undefined : 54,
      alignSelf: cellAlignment,
      width: '100%',
      maxWidth: '100%'
    }],
    children: keyInData ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: [_TableTypes.styles.cellContent, {
        justifyContent: cellAlignment
      }, cellStyle],
      children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        showToolTipOnCutText: true,
        textAlign: cellAlignment === 'flex-end' ? 'right' : undefined,
        ellipsizeMode: "tail",
        numberOfLines: props.sLayout ? undefined : 1,
        fixedWidth: "100%",
        centeredBox: false,
        children: out
      })
    }) : null
  }, `cell-${props.item.id}-${props.index}`);
}