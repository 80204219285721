var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogTitle = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DialogTitle = exports.DialogTitle = function (_PureComponent) {
  function DialogTitle() {
    (0, _classCallCheck2.default)(this, DialogTitle);
    return _callSuper(this, DialogTitle, arguments);
  }
  (0, _inherits2.default)(DialogTitle, _PureComponent);
  return (0, _createClass2.default)(DialogTitle, [{
    key: "renderRightIcon",
    value: function renderRightIcon() {
      var iconRight = this.props.iconRight;
      if (iconRight !== undefined) {
        return (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, iconRight));
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
    }
  }, {
    key: "renderAdditionalIcons",
    value: function renderAdditionalIcons() {
      var additionalIcons = this.props.additionalIcons;
      if (additionalIcons != null && additionalIcons.length > 0) {
        var renderedIcons = [];
        additionalIcons.forEach(function (icon, index) {
          renderedIcons.push((0, _react.createElement)(_Icon.Icon, Object.assign({}, icon, {
            key: `additionalButtons${icon.icon}${index}`
          })));
        });
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: renderedIcons
        });
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
    }
  }, {
    key: "renderLeftIcons",
    value: function renderLeftIcons() {
      var leftIcons = this.props.leftIcons;
      if (leftIcons != null && leftIcons.length > 0) {
        var renderedIcons = [];
        leftIcons.forEach(function (icon, index) {
          renderedIcons.push((0, _react.createElement)(_Icon.Icon, Object.assign({}, icon, {
            key: `additionalButtons${icon.icon}${index}`
          })));
        });
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            width: 36 * leftIcons.length
          },
          children: renderedIcons
        });
      }
      return null;
    }
  }, {
    key: "renderTitle",
    value: function renderTitle() {
      var _this$props = this.props,
        children = _this$props.children,
        color = _this$props.color,
        onPressTitle = _this$props.onPressTitle;
      if (typeof children === 'string') {
        return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          color: color,
          centeredBox: true,
          type: _MaterialText.MaterialTextTypes.H6,
          onPress: onPressTitle,
          children: children
        });
      }
      return children;
    }
  }, {
    key: "renderBackIcon",
    value: function renderBackIcon() {
      var _this$props2 = this.props,
        backButton = _this$props2.backButton,
        leftIcons = _this$props2.leftIcons;
      if (backButton != null || leftIcons != null && leftIcons != null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: 'auto',
            minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
            maxHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(128),
            flexDirection: 'row'
          },
          children: [backButton != null && (0, _jsxRuntime.jsx)(_Icon.Icon, {
            onPress: backButton,
            icon: "arrow-left",
            toolTip: _I18n.I18n.m.getMessage('back')
          }), this.renderLeftIcons(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
              alignItems: 'center',
              justifyContent: 'center'
            },
            collapsable: false,
            children: this.renderTitle()
          })]
        });
      }
      return this.renderTitle();
    }
  }, {
    key: "render",
    value: function render() {
      var inContent = this.props.inContent;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: 'auto',
          minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(64),
          maxHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(128),
          width: '100%',
          paddingHorizontal: inContent ? 0 : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
          paddingVertical: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
          justifyContent: 'space-between',
          flexDirection: 'row'
        },
        children: [this.renderBackIcon(), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: [this.renderAdditionalIcons(), this.renderRightIcon()]
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        children: '',
        color: '#232F34',
        inContent: false
      };
    }
  }]);
}(_react.PureComponent);