var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouterControl = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _Url = require("cqrs-shared/build/src/uri/Url");
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var _queryString = _interopRequireDefault(require("query-string"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _tsEvents = require("ts-events");
var _userContext = _interopRequireDefault(require("../../userContext"));
var _Alert = require("../components/Alert");
var _ContainedButton = require("../components/button/ContainedButton");
var _Dialog = require("../components/Dialog");
var _DialogContainer = require("../components/dialog/DialogContainer");
var _Fab = require("../components/Fab");
var _FormDialogContainer = require("../components/forminput/FormDialogContainer");
var _ContentHeader = require("../components/header/ContentHeader");
var _Menu = require("../components/Menu");
var _MenuRaw = require("../components/MenuRaw");
var _MaterialText = require("../components/text/MaterialText");
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _Routing = require("./Routing");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var uuid = require('uuid');
var RouterControl = exports.RouterControl = function (_PureComponent) {
  function RouterControl(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, RouterControl);
    _this = _callSuper(this, RouterControl, [props, context]);
    _this.urlChanged = new _tsEvents.AsyncEvent();
    _this.dialogs = [];
    _this.routeBack = function () {
      if (_this.props.history != null) {
        var closedOverlays = _this.closeOverlays();
        if (!closedOverlays) {
          _this.props.history.goBack();
        }
      }
    };
    _this.routeHandler = function (_change) {
      _this.closeMenus();
      var newState = RouterControl.getStateFromHistory(_this.state, {
        location: _this.props.location
      }, _this.props.history.action);
      if (JSON.stringify(_this.state) !== JSON.stringify(newState)) {
        var _Dialog$instance;
        if (newState.direction === 'backward' && ((_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.dialogState) === 'opened') {
          var _Dialog$instance2;
          (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.close();
        }
        RouterControl.route = RouterControl.renderRoute(newState, _this.props.history);
        _this.setState(newState);
      }
    };
    _this.routeShouldChangeHandler = function (change) {
      var url = typeof change.path === 'string' ? _Url.Url.getURLfromString(change.path) : change.path;
      if (_ErrorReporter.ErrorReporter.routingInstrumentation != null) {
        try {
          var name = url.pathname;
          var s = name.split('/');
          if (s.length > 0) {
            var newPath = '';
            for (var p of s) {
              if (p.length > 0) {
                newPath += `/${p.includes('-') ? ':id' : p}`;
              }
            }
            name = newPath.length === 0 ? '/' : newPath;
          }
          _ErrorReporter.ErrorReporter.routingInstrumentation.onRouteWillChange({
            name: name,
            op: 'navigation',
            data: {
              url: url.href
            }
          });
        } catch (e) {}
      }
      var basename = _ThemeManager.ThemeManager.style.basename;
      if (basename != null && basename.length > 0) {
        if (basename.substr(basename.length - 1) === '/') {
          basename = basename.substr(0, basename.length - 1);
        }
        if (url.pathname.length > basename.length && url.pathname.substr(0, basename.length) === basename) {
          url.pathname = url.pathname.substr(basename.length);
          if (url.pathname.substr(0, 1) !== '/') {
            url.pathname = `/${url.pathname}`;
          }
        }
      }
      _this.goTo(url, change.replace, change.state);
    };
    _this.alert = function (alertOptions) {
      if (_Alert.Alert.instance != null) {
        var _Alert$instance, _Alert$instance2;
        var alertButtons = alertOptions.buttons != null && alertOptions.buttons.length > 0 ? alertOptions.buttons : [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          title: "ok",
          onPress: (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close,
          full: false,
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "b1")];
        (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.open({
          title: alertOptions.title,
          closeOnTouchOutside: alertOptions.closeOnTouchOutside,
          isBlocking: true,
          content: alertOptions.text,
          buttons: alertButtons,
          maxWidth: _ThemeManager.ThemeManager.style.isSmallScreen ? 280 : 560
        });
      } else {
        setTimeout(function () {
          return _this.alert(alertOptions);
        }, 500);
      }
    };
    _this.analyseRoute = function (_location) {
      var _action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'push';
      var fullpath = `${_location.pathname}${_location.search}${_location.hash}`;
      _this.currentUrl = _Url.Url.getURLfromString(fullpath);
      if (_reactNativeWeb.Platform.OS === 'web' && _action === 'push' && _Routing.Routing.history.length >= 50 && _Routing.Routing.initialIndex > 0) {
        _Routing.Routing.initialIndex -= 1;
      }
      _Routing.Routing.instance.routeHasChanged(fullpath.toString());
    };
    _this.terminateAppDialogVisible = false;
    _this.closeTerminateAppDialog = function (_e) {
      var _Dialog$instance3;
      _this.onCloseTerminateAppDialog();
      (_Dialog$instance3 = _Dialog.Dialog.instance) == null || _Dialog$instance3.close();
    };
    _this.onCloseTerminateAppDialog = function (_e) {
      _this.terminateAppDialogVisible = false;
    };
    _this.backHandler = function () {
      if (_this.props.history && _this.props.history.index > 0) {
        _Routing.Routing.instance.goBack();
        return true;
      }
      if (!_this.terminateAppDialogVisible) {
        var _Dialog$instance4;
        _this.terminateAppDialogVisible = true;
        (_Dialog$instance4 = _Dialog.Dialog.instance) == null || _Dialog$instance4.open({
          title: _I18n.I18n.m.getMessage('closeAppTitle'),
          content: _I18n.I18n.m.getMessage('closeAppText'),
          onClose: _this.onCloseTerminateAppDialog,
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('cancel'),
            onPress: _this.closeTerminateAppDialog,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, 1), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('close'),
            onPress: _this.exitApp,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, 2)]
        });
      }
      return true;
    };
    _this.blocked = false;
    _this.block = function (block) {
      _this.blocked = block;
      if (block) {
        if (RouterControl.unblock != null) {
          RouterControl.unblock();
        }
        RouterControl.unblock = _this.props.history.block(function (_tx) {
          if (_this.blocked) {
            requestAnimationFrame(function () {
              _this.block(_this.blocked);
            });
          }
          return !_this.blocked;
        });
      } else if (RouterControl.unblock != null) {
        RouterControl.unblock();
        RouterControl.unblock = null;
        _this.props.history.block(null);
      }
    };
    _this.dialogClosed = function (id) {
      for (var i = 0; i < _this.dialogs.length; i += 1) {
        if (_this.dialogs[i].dialogId === id) {
          _this.dialogs.splice(i, 1);
          break;
        }
      }
      _this.forceUpdate();
    };
    _this.exitApp = function () {
      _reactNativeWeb.BackHandler.exitApp();
    };
    _this.state = {
      path: '',
      direction: 'forward',
      hash: '',
      query: '',
      hiddenState: undefined,
      size: props.size,
      sideBarWidth: 0
    };
    RouterControl._instance = _this;
    _Routing.Routing.initialIndex = props.history.length;
    _Routing.Routing.history = _this.props.history;
    RouterControl.route = RouterControl.renderRoute(_this.state, props.history);
    if (_reactNativeWeb.Platform.OS === 'android') {
      _reactNativeWeb.BackHandler.addEventListener('hardwareBackPress', _this.backHandler);
    }
    return _this;
  }
  (0, _inherits2.default)(RouterControl, _PureComponent);
  return (0, _createClass2.default)(RouterControl, [{
    key: "currentUrl",
    get: function get() {
      return this._currentUrl;
    },
    set: function set(value) {
      this._currentUrl = value;
      this.urlChanged.post(value);
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.history != null) {
        this.props.history.listen(this.analyseRoute);
        this.analyseRoute(this.props.history.location);
      }
      _Routing.Routing.instance.routeBlock.detach(this.block);
      _Routing.Routing.instance.routeBlock.attach(this.block);
      _Routing.Routing.instance.dialogClosedEvent.detach(this.dialogClosed);
      _Routing.Routing.instance.dialogClosedEvent.attach(this.dialogClosed);
      _Routing.Routing.instance.routeShouldChanged.detach(this.routeShouldChangeHandler);
      _Routing.Routing.instance.routeShouldChanged.attach(this.routeShouldChangeHandler);
      _Routing.Routing.instance.routeShouldChangedAttached = true;
      _Routing.Routing.instance.routeChanged.detach(this.routeHandler);
      _Routing.Routing.instance.routeChanged.attach(this.routeHandler);
      _Routing.Routing.instance.routeBack.detach(this.routeBack);
      _Routing.Routing.instance.routeBack.attach(this.routeBack);
      _Routing.Routing.instance.alert.detach(this.alert);
      _Routing.Routing.instance.alert.attach(this.alert);
      RouterControl.isMounted = true;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      RouterControl.isMounted = false;
      _Routing.Routing.instance.alert.detach(this.alert);
      _Routing.Routing.instance.routeBlock.detach(this.block);
      _Routing.Routing.instance.dialogClosedEvent.detach(this.dialogClosed);
      _Routing.Routing.instance.routeShouldChanged.detach(this.routeShouldChangeHandler);
      _Routing.Routing.instance.routeBack.detach(this.routeBack);
      _Routing.Routing.instance.routeChanged.detach(this.routeHandler);
    }
  }, {
    key: "openUnRoutedDialog",
    value: function openUnRoutedDialog(state) {
      var _Dialog$instance5;
      var openImmediately = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      (_Dialog$instance5 = _Dialog.Dialog.instance) == null || _Dialog$instance5.open(state, openImmediately);
    }
  }, {
    key: "parseDialogs",
    value: function parseDialogs(state) {
      var _this2 = this;
      var query = state.query,
        hash = state.hash,
        hiddenState = state.hiddenState;
      var search;
      if (query != null) {
        search = _queryString.default.parse(query);
        if (search.data != null) {
          search.data = _queryString.default.parse(search.data);
        }
      }
      if (search != null && search.d != null) {
        var dialogPath = search.d;
        var dialog = _Routing.Routing.getDialogForPath(dialogPath.split('/'));
        if (dialog != null) {
          var exists = -1;
          for (var i = 0; i < this.dialogs.length; i += 1) {
            if (this.dialogs[i].dialogName === dialog.path && !this.dialogs[i].closed) {
              exists = i;
            }
          }
          var dialogId = exists > -1 ? this.dialogs[exists].dialogId : uuid.v1();
          var size = this.props.size;
          var props = Object.assign({}, dialog.optionalDialogContainerProps, {
            size: size,
            dialogId: dialogId,
            hash: hash
          }, search, {
            dialogType: dialog.dialogType,
            pathvars: dialog.pathvars,
            hiddenState: Object.assign({}, hiddenState),
            opened: exists > -1,
            key: `dialog_${dialogId}`,
            onSideBarWidthChange: function onSideBarWidthChange(width) {
              return _this2.setState({
                sideBarWidth: width
              }, function () {
                return _this2.props.onSidebarWidthChange(width);
              });
            }
          });
          var d = null;
          if (dialog.extendend) {
            d = {
              dialogId: dialogId,
              dialogName: dialog.path,
              component: dialog.component(props)
            };
          } else if (dialog.dialogType === 'form') {
            d = {
              dialogId: dialogId,
              dialogName: dialog.path,
              component: (0, _jsxRuntime.jsx)(_FormDialogContainer.FormDialogContainer, Object.assign({}, props, {
                dialogType: "responsible",
                children: dialog.component(props)
              }))
            };
          } else {
            d = {
              dialogId: dialogId,
              dialogName: dialog.path,
              component: (0, _jsxRuntime.jsx)(_DialogContainer.DialogContainer, Object.assign({}, props, {
                children: dialog.component(props)
              }))
            };
          }
          if (exists > -1) {
            this.dialogs[exists] = Object.assign({}, d, {
              closed: false
            });
          }
          return d;
        }
      }
      return null;
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var sideBarWidth = this.state.sideBarWidth;
      var dialogs = this.renderDialogs();
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: _ThemeManager.ThemeManager.style.absoluteStyle,
        pointerEvents: _reactNativeWeb.Platform.OS === 'web' ? 'auto' : 'box-none',
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
            right: sideBarWidth
          }),
          children: [RouterControl.route(), (0, _jsxRuntime.jsx)(_Fab.Fab, {
            size: size
          })]
        }), (0, _jsxRuntime.jsx)(_ContentHeader.ContentHeader, {
          size: size
        }), dialogs]
      });
    }
  }, {
    key: "renderDialogs",
    value: function renderDialogs() {
      var d = this.parseDialogs(this.state);
      if (d != null) {
        if (!d.component.props.opened) {
          this.dialogs.push(Object.assign({}, d, {
            closed: false
          }));
        }
      }
      var dialogs = [];
      for (var i = 0; i < this.dialogs.length; i += 1) {
        if (d == null || d.dialogName !== this.dialogs[i].dialogName) {
          _Routing.Routing.instance.dialogShouldCloseEvent.post(this.dialogs[i].dialogId);
          this.dialogs[i].closed = true;
        }
        dialogs.push(this.dialogs[i].component);
      }
      return dialogs;
    }
  }, {
    key: "closeMenus",
    value: function closeMenus() {
      var _Menu$instance, _MenuRaw$instance;
      if (((_Menu$instance = _Menu.Menu.instance) == null ? void 0 : _Menu$instance.menuState) === 'opened') {
        var _Menu$instance2;
        (_Menu$instance2 = _Menu.Menu.instance) == null || _Menu$instance2.closeNow();
      }
      if (((_MenuRaw$instance = _MenuRaw.MenuRaw.instance) == null ? void 0 : _MenuRaw$instance.menuRawState) === 'opened') {
        var _MenuRaw$instance2;
        (_MenuRaw$instance2 = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance2.closeNow();
      }
    }
  }, {
    key: "closeOverlays",
    value: function closeOverlays() {
      var _Alert$instance3, _Dialog$instance6;
      if (((_Alert$instance3 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance3.dialogState) === _Dialog.DialogState.opened) {
        return true;
      }
      if (((_Dialog$instance6 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance6.dialogState) === _Dialog.DialogState.opened) {
        var _Dialog$instance7;
        (_Dialog$instance7 = _Dialog.Dialog.instance) == null || _Dialog$instance7.close();
        return true;
      }
      return false;
    }
  }, {
    key: "goTo",
    value: function goTo(url) {
      var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var state = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var history = this.props.history;
      if (history != null) {
        if (replace) {
          history.replace(url, state);
        } else {
          history.push(url, state);
        }
        this.currentUrl = url;
      } else {}
    }
  }], [{
    key: "instance",
    get: function get() {
      if (RouterControl._instance == null) {
        throw new Error('RouterControl not defined, add Wrapper!');
      }
      return RouterControl._instance;
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(_nextProps, _prevState) {
      var state = RouterControl.getStateFromHistory(_prevState, {
        location: _nextProps.location
      }, _nextProps.history.action);
      state.size = _nextProps.size;
      if (JSON.stringify(state) !== JSON.stringify(_prevState)) {
        RouterControl.route = RouterControl.renderRoute(state, _nextProps.history);
        return state;
      }
      return null;
    }
  }, {
    key: "notFoundRoute",
    value: function notFoundRoute(_props) {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, _ThemeManager.ThemeManager.style.contentPadding, {
          paddingTop: 96,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }),
        pointerEvents: "box-none",
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H3,
          centeredText: true,
          centeredBox: true,
          children: _I18n.I18n.m.getMessage('notFound')
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('goToHome'),
            onPress: _Routing.Routing.instance.goToButton('/'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          })
        })]
      });
    }
  }, {
    key: "getStateFromHistory",
    value: function getStateFromHistory(currentState, history, direction) {
      var state = Object.assign({}, currentState);
      if (history.location != null) {
        state.path = history.location.pathname != null ? history.location.pathname : '';
        if (history.location.state != null) {
          state.hiddenState = history.location.state;
        }
        var hash = history.location.hash;
        state.hash = hash != null && hash.length > 0 && hash.substr(0, 1) === '#' ? hash.substr(1) : hash;
        state.query = history.location.search;
        state.direction = direction.toUpperCase() === 'POP' ? 'backward' : 'forward';
      }
      return state;
    }
  }]);
}(_react.PureComponent);
RouterControl.contextType = _userContext.default;
RouterControl.closeEvent = new _tsEvents.AsyncEvent();
RouterControl.isMounted = false;
RouterControl.route = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
RouterControl.renderRoute = function (forState, history) {
  return function () {
    var path = forState.path,
      query = forState.query,
      hash = forState.hash,
      size = forState.size;
    var search;
    if (query != null) {
      search = _queryString.default.parse(query);
    }
    var route = _Routing.Routing.getRouteForPath(path.split('/'));
    if (route != null && route.component != null) {
      var c;
      try {
        c = route.component(Object.assign({
          history: history,
          hash: hash
        }, search, {
          pathvars: route.pathvars,
          size: size
        }));
        return c;
      } catch (e) {}
    }
    return RouterControl.notFoundRoute(Object.assign({
      history: history,
      hash: hash,
      path: path
    }, search));
  };
};