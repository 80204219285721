var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilePicketComp = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../upmesh-support/node_modules/react"));
var _FileDropHandler = require("../theme/components/file/FileDropHandler");
var _jsxRuntime = require("../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var mimeTypes = Object.freeze({
  allFiles: '*/*',
  audio: 'audio/*',
  csv: ['text/csv', 'text/comma-separated-values'],
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  images: 'image/*',
  pdf: 'application/pdf',
  plainText: 'text/plain',
  json: 'application/json',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  video: 'video/*',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip'
});
var FilePicketComp = exports.FilePicketComp = function (_PureComponent) {
  function FilePicketComp() {
    var _this;
    (0, _classCallCheck2.default)(this, FilePicketComp);
    _this = _callSuper(this, FilePicketComp, arguments);
    _this.gotFile = function (e) {
      var files = e.target.files;
      if (files != null && files.length > 0) {
        var gotFile = _this.props.gotFile;
        if (!Array.isArray(files)) {
          var fs = [];
          for (var f of files) {
            fs.push(f);
          }
          gotFile(fs);
        } else {
          gotFile(files);
        }
      }
      e.target.value = null;
    };
    return _this;
  }
  (0, _inherits2.default)(FilePicketComp, _PureComponent);
  return (0, _createClass2.default)(FilePicketComp, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props = this.props,
        multiple = _this$props.multiple,
        dragNdrop = _this$props.dragNdrop,
        filter = _this$props.filter,
        gotFile = _this$props.gotFile,
        defaultHandler = _this$props.defaultHandler;
      if (dragNdrop) {
        this.dropKey = _FileDropHandler.FileDropHandler.addTarget({
          onGotFile: gotFile,
          text: '',
          filter: filter,
          multiHandlerDialogText: '',
          multiFiles: multiple,
          defaultHandler: defaultHandler === true
        });
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.dropKey != null) {
        _FileDropHandler.FileDropHandler.removeTarget(this.dropKey);
        this.dropKey = undefined;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        id = _this$props2.id,
        multiple = _this$props2.multiple,
        filter = _this$props2.filter,
        selectType = _this$props2.selectType;
      if (selectType === 'folder') {
        return (0, _jsxRuntime.jsx)("input", {
          style: {
            display: 'none'
          },
          id: id,
          type: "file",
          multiple: multiple,
          onChange: this.gotFile,
          directory: "true",
          webkitdirectory: "true",
          webkitentries: "true"
        }, id);
      }
      var filetypes;
      if (filter == null || filter.includes('allFiles')) filetypes = ['allFiles'];else filetypes = filter;
      var mimes = filetypes.map(function (type) {
        return mimeTypes[type];
      }).join(',');
      return (0, _jsxRuntime.jsx)("input", {
        style: {
          display: 'none'
        },
        id: id,
        type: "file",
        multiple: multiple,
        onChange: this.gotFile,
        accept: mimes,
        webkitentries: "true"
      }, id);
    }
  }]);
}(_react.PureComponent);