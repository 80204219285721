var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icon = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _reactNativeVectorIcons = require("react-native-vector-icons");
var _MaterialCommunityIcons = _interopRequireDefault(require("react-native-vector-icons/MaterialCommunityIcons"));
var icoMoonConfig = _interopRequireWildcard(require("../../assets/fonts/selection.json"));
var _ThemeManager = require("../ThemeManager");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ICON = (0, _reactNativeVectorIcons.createIconSetFromIcoMoon)(icoMoonConfig);
var Icon = exports.Icon = function (_PureComponent) {
  function Icon(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Icon);
    _this = _callSuper(this, Icon, [props]);
    _this.animatedRotation = new _reactNativeWeb.Animated.Value(0);
    if (props.rotation != null) {
      _this.animatedRotation.setValue(props.rotation);
    }
    var iconSize = props.iconSize != null ? props.iconSize : 24;
    _this.state = {
      iconSize: iconSize,
      outerSize: props.outerSize != null ? props.outerSize : iconSize * 1.5,
      iconIconMoon: false,
      icon: props.icon != null ? props.icon : 'help',
      color: props.color != null ? props.color : _ThemeManager.ThemeManager.style.defaultIconColor,
      onPress: props.onPress != null ? props.onPress : undefined,
      rotation: 0,
      disabled: props.disabled != null ? props.disabled : false
    };
    return _this;
  }
  (0, _inherits2.default)(Icon, _PureComponent);
  return (0, _createClass2.default)(Icon, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      if (this.props.rotation != null && prevProps.rotation !== this.props.rotation) {
        this.animateRotation(this.props.rotation);
      }
    }
  }, {
    key: "renderIcon",
    value: function renderIcon() {
      var _this$state = this.state,
        icon = _this$state.icon,
        iconIconMoon = _this$state.iconIconMoon,
        iconSize = _this$state.iconSize,
        onPress = _this$state.onPress,
        color = _this$state.color,
        outerSize = _this$state.outerSize,
        disabled = _this$state.disabled;
      var _this$props = this.props,
        backgroundColor = _this$props.backgroundColor,
        onPressIn = _this$props.onPressIn,
        radius = _this$props.radius,
        elevation = _this$props.elevation,
        toolTip = _this$props.toolTip,
        borderColor = _this$props.borderColor,
        borderStyle = _this$props.borderStyle,
        accessibilityLabel = _this$props.accessibilityLabel;
      var iconElement;
      var iconColor = disabled ? _ThemeManager.ThemeManager.style.disabledColor : color;
      var relativeIconSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(iconSize);
      var relativeIconOuterSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(outerSize);
      var spin = this.animatedRotation.interpolate({
        inputRange: [0, 360],
        outputRange: ['0deg', '360deg']
      });
      var ICO = iconIconMoon ? ICON : _MaterialCommunityIcons.default;
      iconElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        style: {
          backgroundColor: backgroundColor,
          transform: [{
            rotate: spin
          }],
          width: relativeIconOuterSize,
          height: relativeIconOuterSize,
          overflow: 'hidden',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 0
        },
        children: (0, _jsxRuntime.jsx)(ICO, {
          name: icon,
          size: relativeIconSize,
          color: iconColor,
          style: {
            width: relativeIconSize,
            height: relativeIconSize,
            minWidth: relativeIconSize,
            minHeight: relativeIconSize,
            lineHeight: relativeIconSize,
            color: iconColor,
            alignSelf: 'center',
            textAlign: 'center'
          }
        })
      });
      var borderViewStyle = {
        borderStyle: borderStyle,
        borderColor: borderColor != null ? borderColor : iconColor,
        borderWidth: borderStyle == null ? 0 : 1
      };
      if (onPress != null || onPressIn != null) {
        var tID = accessibilityLabel != null ? accessibilityLabel : toolTip.length > 0 ? toolTip : icon;
        var elevationWebStyle = elevation != null && _ThemeManager.ThemeManager.style[`elevation${elevation}`] != null ? _ThemeManager.ThemeManager.style[`elevation${elevation}`] : {};
        iconElement = (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          testID: tID,
          accessibilityLabel: tID,
          style: Object.assign({
            elevation: elevation
          }, elevationWebStyle, borderViewStyle, {
            width: relativeIconOuterSize,
            height: relativeIconOuterSize,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: radius != null ? radius : relativeIconOuterSize / 2
          }),
          hoverColor: disabled ? undefined : _ThemeManager.ThemeManager.style.getDefaultHoverColor(iconColor),
          rippleColor: _ThemeManager.ThemeManager.style.getDefaultRippleColor(iconColor),
          onPress: onPress,
          onPressIn: onPressIn,
          toolTip: toolTip,
          disabled: disabled,
          children: iconElement
        }, tID);
      } else {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({}, borderViewStyle, {
            width: _reactNativeWeb.Platform.OS !== 'ios' ? relativeIconOuterSize : 'auto',
            height: relativeIconOuterSize,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: radius != null ? radius : relativeIconOuterSize / 2
          }),
          accessibilityLabel: accessibilityLabel,
          children: iconElement
        });
      }
      return iconElement;
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        badge = _this$props2.badge,
        badgeColor = _this$props2.badgeColor;
      var iconSize = this.props.iconSize != null ? this.props.iconSize : 24;
      var outerSize = this.props.outerSize != null ? this.props.outerSize : iconSize * 1.5;
      var badgeSize = 16;
      if (badge == null || badge.length === 0) return this.renderIcon();
      var fontscaling = badge.length > 1 ? 4 : 2;
      var diff = (outerSize - iconSize) / 2;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: outerSize,
          height: outerSize,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'visible'
        },
        children: [this.renderIcon(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            top: diff - 6,
            right: diff - 6,
            minWidth: badgeSize,
            height: badgeSize,
            backgroundColor: badgeColor != null ? badgeColor : _ThemeManager.ThemeManager.style.brandPrimary,
            borderRadius: badgeSize / 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
            style: {
              height: badgeSize,
              color: '#FFFFFF',
              textAlign: 'center',
              fontSize: badgeSize - fontscaling,
              lineHeight: badgeSize,
              fontFamily: 'roboto',
              alignSelf: 'center'
            },
            numberOfLines: 1,
            children: badge
          })
        })]
      });
    }
  }, {
    key: "animateRotation",
    value: function animateRotation(radius) {
      _reactNativeWeb.Animated.timing(this.animatedRotation, {
        duration: 195,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        toValue: radius
      }).start();
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      return Object.assign({}, prevState, nextProps);
    }
  }]);
}(_react.PureComponent);
Icon.defaultProps = {
  borderStyle: undefined
};