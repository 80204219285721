var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CacheableImage = CacheableImage;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _connectionContext = _interopRequireDefault(require("../../../connectionContext"));
var _I18n = require("../../i18n/I18n");
var _Icon = require("../Icon");
var _Lightbox = require("../lightbox/Lightbox");
var _WebImageLoader = require("./WebImageLoader");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var getSources = function getSources(props) {
  var source = props.source,
    style = props.style,
    localSource = props.localSource,
    width = props.width,
    height = props.height,
    dontResizeToView = props.dontResizeToView;
  var bigSource;
  var s = typeof source === 'string' ? {
    uri: source
  } : source;
  var imageSource = s;
  var headers = {};
  if (localSource == null && (props.auth == null || props.auth) && _CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
  var w = style != null && typeof style['width'] === 'number' ? style['width'] : width;
  var h = style != null && typeof style['height'] === 'number' ? style['height'] : height;
  if (localSource != null) {
    if (_reactNativeWeb.Platform.OS === 'android' && !localSource.startsWith('file://')) {
      imageSource = {
        uri: `file://${localSource}`
      };
      bigSource = `file://${localSource}`;
    } else {
      imageSource = {
        uri: localSource
      };
      bigSource = localSource;
    }
  } else {
    if (dontResizeToView) return {
      bigSource: '',
      source: imageSource
    };
    if (s != null && s['uri'] != null && typeof s['uri'] === 'string' && s['uri'].substr(0, 4) === 'http') {
      var _props$lastModified, _props$lastModified2;
      bigSource = _reactNativeWeb.Platform.OS === 'web' ? `${s['uri']}?${(_props$lastModified = props.lastModified) == null ? void 0 : _props$lastModified.getTime()}` : `${s['uri']}?w=4096&h=4096&${(_props$lastModified2 = props.lastModified) == null ? void 0 : _props$lastModified2.getTime()}`;
    }
    if (w != null && h != null) {
      var w2 = Math.ceil(w * _reactNativeWeb.PixelRatio.get() / 128) * 128;
      var h2 = Math.ceil(h * _reactNativeWeb.PixelRatio.get() / 128) * 128;
      if (s != null && typeof s['uri'] === 'string' && s['uri'].substr(0, 4) === 'http') {
        var _props$lastModified3;
        var formPrefix = s['uri'].indexOf('?') > -1 ? '&' : '?';
        imageSource = {
          uri: `${s['uri']}${formPrefix}w=${w2}&h=${h2}&${(_props$lastModified3 = props.lastModified) == null ? void 0 : _props$lastModified3.getTime()}`,
          headers: headers
        };
      }
    }
  }
  return {
    bigSource: bigSource,
    source: imageSource
  };
};
function CacheableImage(props) {
  var connection = (0, _react.useContext)(_connectionContext.default);
  var _useState = (0, _react.useState)(props.localSource != null ? false : props.onlyOnConnectedServer ? !connection.connectedToServer : false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    notFound = _useState2[0],
    setNotFound = _useState2[1];
  var sources = getSources(props);
  var _useState3 = (0, _react.useState)(connection.connectedToServer),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    notReachable = _useState4[0],
    setNotReachable = _useState4[1];
  var _useState5 = (0, _react.useState)(),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    webSource = _useState6[0],
    setWebSource = _useState6[1];
  var _useState7 = (0, _react.useState)({
      show: false,
      scaleFactor: 1
    }),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    showBigSource = _useState8[0],
    setShowBigSource = _useState8[1];
  var controller;
  var onLoadError = function onLoadError(_e) {
    var connectedToServer = connection.connectedToServer;
    if (props.localSource == null && !connectedToServer) {
      setNotFound(true);
      setNotReachable(true);
    } else {
      setNotReachable(false);
      setNotFound(true);
    }
    if (props.onNotFound) props.onNotFound();
  };
  var onLoadEnd = function onLoadEnd() {
    if (props.onLoadEnd != null) props.onLoadEnd();
  };
  var loadWebImageWithHeader = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* () {
      return _WebImageLoader.WebImageLoader.loadWebImageWithHeader(sources, {
        localSource: props.localSource,
        onlyOnConnectedServer: props.onlyOnConnectedServer || props.onlyOnConnectedServer == null,
        auth: props.auth
      }, connection, onLoadError, controller);
    });
    return function loadWebImageWithHeader() {
      return _ref.apply(this, arguments);
    };
  }();
  (0, _react.useEffect)(function () {
    var source = sources.source;
    if (_reactNativeWeb.Platform.OS === 'web' && source['uri'] != null) {
      if (controller == null) {
        controller = new AbortController();
      }
      loadWebImageWithHeader().then(function (s) {
        if (s != null) {
          setWebSource(s.webSource);
          setNotFound(s.notFound);
          setNotReachable(s.notReachable);
        }
      }).catch(function (err) {
        onLoadError(err);
      });
      return function () {
        var _controller;
        return (_controller = controller) == null ? void 0 : _controller.abort();
      };
    }
    return function () {};
  }, [props.source, connection.connectedToServer]);
  var getSize = function getSize() {
    var style = props.style,
      width = props.width,
      height = props.height;
    var newWidth = width != null ? width : style != null && style['width'] != null ? style['width'] : 64;
    var newHeight = height != null ? height : style != null && style['height'] != null ? style['height'] : 64;
    return {
      width: newWidth,
      height: newHeight
    };
  };
  var renderImage = function renderImage(width, height) {
    var source = sources.source;
    if (_reactNativeWeb.Platform.OS === 'web' && webSource == null) return null;
    var style = props.style != null ? _reactNativeWeb.StyleSheet.flatten([props.style, {
      width: width,
      height: height
    }]) : {
      width: width,
      height: height
    };
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, Object.assign({}, props, {
      source: webSource != null ? {
        uri: webSource
      } : source,
      style: style,
      onLoadEnd: onLoadEnd,
      onError: onLoadError
    }));
  };
  var renderBigImage = function renderBigImage(width, height) {
    var bigSource = sources.bigSource;
    var scale = 1 / showBigSource.scaleFactor;
    var transform = [{
      scale: scale
    }, {
      translateX: (width * scale - width) / 2 / scale
    }, {
      translateY: (height * scale - height) / 2 / scale
    }];
    if (props.style != null && props.style.transform != null && props.style.transform.length > 0) {
      transform.push.apply(transform, (0, _toConsumableArray2.default)(props.style.transform));
    }
    var style = props.style != null ? _reactNativeWeb.StyleSheet.flatten([props.style, {
      width: width,
      height: height,
      transform: transform
    }]) : {
      width: width,
      height: height,
      transform: [{
        scale: scale
      }, {
        translateX: (width * scale - width) / 2 / scale
      }, {
        translateY: (height * scale - height) / 2 / scale
      }]
    };
    if (showBigSource.show === true && bigSource != null) {
      var _props$lastModified4;
      var headers = {};
      if (props.localSource == null && (props.auth == null || props.auth) && _CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
      return (0, _react.createElement)(CacheableImage, Object.assign({}, props, {
        resizeMethod: "scale",
        width: width,
        height: height,
        key: `bigImage_${bigSource}_${(_props$lastModified4 = props.lastModified) == null ? void 0 : _props$lastModified4.toString()}`,
        onlyOnConnectedServer: false,
        lightBox: false,
        localSource: undefined,
        dontResizeToView: true,
        showLoading: false,
        showNotFound: false,
        source: {
          uri: bigSource,
          headers: headers
        },
        style: style
      }));
    }
    return null;
  };
  var toggleBigSourceVisibility = function toggleBigSourceVisibility(visible) {
    return function (scaleFactor) {
      setShowBigSource({
        show: visible,
        scaleFactor: scaleFactor != null ? scaleFactor : 1
      });
      if (_reactNativeWeb.Platform.OS === 'web') {
        loadWebImageWithHeader().then(function (s) {
          if (s != null) {
            setWebSource(s.webSource);
            setNotFound(s.notFound);
            setNotReachable(s.notReachable);
          }
        }).catch(function (err) {
          return void 0;
        });
      }
    };
  };
  var renderImageBox = function renderImageBox() {
    var _getSize = getSize(),
      width = _getSize.width,
      height = _getSize.height;
    var image = renderImage(width, height);
    var lightBox = props.lightBox,
      lightBoxProps = props.lightBoxProps,
      panoramaImage = props.panoramaImage,
      auth = props.auth;
    if (lightBox === true || panoramaImage) {
      var source = sources.source,
        bigSource = sources.bigSource;
      var panoramaImageSource = !panoramaImage ? undefined : bigSource != null ? bigSource : source.uri != null ? source.uri : undefined;
      var lp = Object.assign({}, lightBoxProps, {
        auth: auth,
        onOpened: toggleBigSourceVisibility(true),
        onClosed: toggleBigSourceVisibility(false),
        panoramaImageSource: panoramaImageSource,
        imageURL: bigSource != null ? bigSource : source.uri
      });
      var scaleFactor = showBigSource.scaleFactor;
      return (0, _jsxRuntime.jsxs)(_Lightbox.Lightbox, Object.assign({}, lp, {
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'relative',
            top: 0,
            left: 0,
            width: width,
            height: height,
            overflow: 'visible'
          },
          children: image
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: width,
            height: height,
            overflow: 'visible'
          },
          children: renderBigImage(typeof width === 'number' ? width * scaleFactor : width, typeof height === 'number' ? height * scaleFactor : height)
        })]
      }));
    }
    return image;
  };
  var render = function render() {
    var showNotFound = props.showNotFound;
    var _getSize2 = getSize(),
      width = _getSize2.width,
      height = _getSize2.height;
    if (notFound) {
      if (showNotFound !== false) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: width,
            height: height,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center',
              minWidth: 48,
              alignItems: 'center',
              justifyContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: _I18n.I18n.m.getMessage(notReachable ? 'imageNotReachable' : 'imageNotFound'),
              icon: notReachable ? 'file-remove-outline' : 'file-outline'
            })
          })
        });
      }
      return null;
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: width,
        height: height,
        position: 'relative',
        overflow: 'visible'
      },
      children: renderImageBox()
    }, "imageContainer");
  };
  return render();
}
CacheableImage.webcache = {};