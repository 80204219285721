var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SegmentedButton = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SegmentedButton = exports.SegmentedButton = function (_PureComponent) {
  function SegmentedButton(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SegmentedButton);
    _this = _callSuper(this, SegmentedButton, [props]);
    _this.onPressButton = function (index) {
      return function (_e) {
        var _this$props = _this.props,
          onPress = _this$props.onPress,
          multiselect = _this$props.multiselect,
          disabled = _this$props.disabled;
        if (disabled) return;
        var buttons = _this.state.buttons;
        var button = buttons[index];
        if (multiselect) {
          button.multiselected = !button.multiselected;
          buttons[index] = button;
          _this.setState({
            buttons: buttons
          }, function () {
            return _this.forceUpdate();
          });
        }
        if (onPress != null) {
          onPress(index, button);
        }
      };
    };
    _this.state = {
      buttons: props.buttons
    };
    return _this;
  }
  (0, _inherits2.default)(SegmentedButton, _PureComponent);
  return (0, _createClass2.default)(SegmentedButton, [{
    key: "renderButtons",
    value: function renderButtons() {
      var _this$props2 = this.props,
        singleSelectSelected = _this$props2.singleSelectSelected,
        multiselect = _this$props2.multiselect,
        disabled = _this$props2.disabled,
        backgroundColor = _this$props2.backgroundColor,
        textColor = _this$props2.textColor,
        selectedColor = _this$props2.selectedColor,
        disabledColor = _this$props2.disabledColor,
        borderColor = _this$props2.borderColor;
      var buttons = this.state.buttons;
      var renderedButtons = [];
      for (var i = 0; i < buttons.length; i += 1) {
        var button = buttons[i];
        var showCheck = false;
        var bgColor = backgroundColor;
        if (multiselect && button.multiselected === true) {
          showCheck = true;
          bgColor = selectedColor;
        } else if (!multiselect && i === singleSelectSelected) {
          showCheck = true;
          bgColor = selectedColor;
        }
        if (disabled === true && showCheck) {
          bgColor = disabledColor;
        }
        var renderedButton = (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          style: {
            borderLeftWidth: i > 0 ? _ThemeManager.ThemeManager.style.borderWidth : 0,
            borderColor: borderColor,
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: bgColor,
            paddingHorizontal: 12
          },
          accessibilityLabel: this.props.accessibilityLabel,
          onPress: this.onPressButton(i),
          rippleColor: selectedColor,
          hoverColor: selectedColor,
          disabled: disabled,
          children: [showCheck ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "check",
            iconSize: 18,
            toolTip: "",
            color: textColor !== SegmentedButton.defaultProps.textColor ? textColor : _ThemeManager.ThemeManager.style.defaultIconColor
          }) : null, button.icon != null ? (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({
            color: textColor !== SegmentedButton.defaultProps.textColor ? textColor : _ThemeManager.ThemeManager.style.defaultIconColor,
            toolTip: "",
            iconSize: 18
          }, button.icon)) : null, button.title != null ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            color: textColor,
            centeredText: true,
            centeredBox: true,
            type: _MaterialText.MaterialTextTypes.Button,
            children: button.title
          }) : undefined]
        }, `segmentedButton${i}_${button.title}_${button.icon}_${button.key}`);
        renderedButtons.push(renderedButton);
      }
      return renderedButtons;
    }
  }, {
    key: "render",
    value: function render() {
      var density = this.props.density;
      var height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(40 - (density ? density * 4 : 0));
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: height,
          width: '100%',
          borderRadius: 6,
          overflow: 'hidden',
          borderWidth: _ThemeManager.ThemeManager.style.borderWidth,
          borderColor: _ThemeManager.ThemeManager.style.borderColor,
          flexDirection: 'row'
        },
        children: this.renderButtons()
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        singleSelectSelected: 0,
        buttons: [],
        onPress: function onPress(i) {
          return void 0;
        },
        multiselect: false,
        backgroundColor: '#ffffff',
        textColor: _ThemeManager.ThemeManager.style.black87,
        selectedColor: (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).alpha(0.1).toString(),
        disabledColor: (0, _color.default)(_ThemeManager.ThemeManager.style.black38).alpha(0.1).toString(),
        borderColor: _ThemeManager.ThemeManager.style.borderColor
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      return {
        buttons: nextProps.buttons
      };
    }
  }]);
}(_react.PureComponent);