import ApexCharts from 'apexcharts';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
export class UserChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.loadData().catch((err) => console.debug('cant load Data', err));
    }
    async loadData() {
        const { user } = this.props;
        user.sort((a, b) => {
            if (a.createdAt > b.createdAt)
                return -1;
            return 1;
        });
        const newUsers = [];
        const newUsersOverWebsite = [];
        const newUsersInvited = [];
        const total = [];
        if (user.length > 0) {
            const c = new Date(user[0].createdAt);
            let lastDay = new Date(c.getFullYear(), c.getMonth());
            let y = 0;
            let y2 = 0;
            let y3 = 0;
            user.forEach((c, i) => {
                y += 1;
                if (c.origin === 'website') {
                    y2 += 1;
                    y -= 1;
                }
                else if (c.origin === 'invitation') {
                    y3 += 1;
                    y -= 1;
                }
                const d = new Date(c.createdAt);
                const currentDay = new Date(d.getFullYear(), d.getMonth());
                if (lastDay.getTime() !== currentDay.getTime()) {
                    newUsers.push({ x: lastDay.getTime(), y });
                    newUsersOverWebsite.push({ x: lastDay.getTime(), y: y2 });
                    newUsersInvited.push({ x: lastDay.getTime(), y: y3 });
                    total.push({ x: lastDay.getTime(), y: y + y2 + y3 });
                    lastDay = new Date(currentDay.getTime());
                    if (i < user.length) {
                        y = 0;
                        y2 = 0;
                        y3 = 0;
                    }
                }
            });
            newUsers.push({ x: lastDay.getTime(), y: 0 });
            newUsersOverWebsite.push({ x: lastDay.getTime(), y: 0 });
            newUsersInvited.push({ x: lastDay.getTime(), y: 0 });
            total.push({ x: lastDay.getTime(), y: 0 });
        }
        const options = {
            chart: {
                type: 'bar',
                stacked: true,
                width: ResizeEvent.current.contentWidth - 32,
                height: ResizeEvent.current.contentHeight - 64,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    formatter(val, opt) {
                        return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`;
                    },
                    offsetX: 0,
                },
                toolbar: {
                    show: true,
                    offsetX: -24,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                        customIcons: [],
                    },
                    autoSelected: 'zoom',
                },
            },
            title: {
                text: 'Neuregistrierungen pro Monat',
                align: 'left',
            },
            yaxis: {
                labels: {
                    formatter(val) {
                        return val.toFixed(0);
                    },
                },
            },
            xaxis: {
                type: 'datetime',
            },
            series: [
                {
                    name: 'Sonstige',
                    data: newUsers,
                },
                {
                    name: 'Über Website',
                    data: newUsersOverWebsite,
                },
                {
                    name: 'Per Einladung',
                    data: newUsersInvited,
                },
            ],
            tooltip: {
                shared: false,
                y: {
                    formatter(val, i) {
                        return `${val.toFixed(0)} / ${total[i.dataPointIndex].y.toFixed(0)}`;
                    },
                },
                x: {
                    formatter(val) {
                        const d = new Date(val);
                        return `${d.getFullYear()}.${d.getMonth() + 1}`;
                    },
                },
            },
        };
        const chart = new ApexCharts(document.querySelector('#chart'), options);
        chart.render().catch((err) => console.debug('cant render chart!', err));
    }
    render() {
        return (<View>
        <View style={{ width: ResizeEvent.current.contentWidth, height: ResizeEvent.current.contentHeight, paddingTop: 24 }}>
          <div style={{ width: '100%', height: '100%' }} id="chart"/>
        </View>
      </View>);
    }
}
