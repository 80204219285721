import { ExcelDownloadHelper } from 'materialTheme/src/theme/components/file/ExcelDownloadHelper';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import * as XLSX from 'xlsx';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
export class SubscriptionExcelExport {
    static isEmpty(entries) {
        if (entries.length < 1)
            return true;
        return false;
    }
    static async loadCompanyInfo(id) {
        const client = ConnectionHandler.getClient();
        if (client != null) {
            return client.company.getById(id);
        }
        return null;
    }
    static async loadSubscriptionso(date) {
        const client = ConnectionHandler.getClient();
        const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const endMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() + 1, 1, 0, 0, 0 - 1);
        if (client != null) {
            return client.subscription.get({
                filter: `startDate le ${startMonth.toISOString()} and expireDate ge ${endMonth.toISOString()} and testAccount ne true`,
            });
        }
        return [];
    }
    static async createExcel(forDate) {
        const entries = await this.loadSubscriptionso(forDate);
        if (SubscriptionExcelExport.isEmpty(entries)) {
            Routing.instance.alert.post({ text: I18n.m.getMessage('timeTrackingExportExcelNoEntries') });
            return;
        }
        const month = I18n.m.dateCurrent.monthsNames()[forDate.getMonth()];
        const allCleartextColumns = [
            'Ge.Pa.Nr',
            'Ge.Pa.Name',
            'Datum',
            'Artikel',
            'Abrechnungstext',
            'Menge',
            'EP',
            'GP',
        ];
        const workBook = XLSX.utils.book_new();
        workBook.Props = {
            Title: `Abrechnung ${month}`,
            CreatedDate: new Date(),
        };
        workBook.SheetNames.push('Rechnungen');
        workBook.SheetNames.push('Gutschriften');
        const rechnungenData = [];
        rechnungenData.push(allCleartextColumns);
        const gutschriftenData = [];
        gutschriftenData.push(allCleartextColumns);
        let row = 2;
        for (const s of entries) {
            const starts = new Date(s.startDate);
            const paymentIntervallInMonth = s.paymentIntervallInMonth ? s.paymentIntervallInMonth : 1;
            const paymentIntervals = [starts.getMonth()];
            for (let i = starts.getMonth() + paymentIntervallInMonth; i < starts.getMonth() + 12; i += paymentIntervallInMonth) {
                paymentIntervals.push(i >= 12 ? i - 12 : i);
            }
            if (s.companyId != null && paymentIntervals.includes(forDate.getMonth())) {
                const data = [];
                const data2 = [];
                const company = await SubscriptionExcelExport.loadCompanyInfo(s.companyId);
                data.push(company?.partnerCustomerNumber || company?.customerNumber || '', company?.company || '');
                data2.push(12510, 'upmesh');
                data.push(`1.${forDate.getMonth() + 1}.${forDate.getFullYear()}`);
                data2.push(`1.${forDate.getMonth() + 1}.${forDate.getFullYear()}`);
                data.push(1015);
                data2.push(1015);
                const article = s.licenceType === 'collaborator'
                    ? 'Mitarbeiterlizenz'
                    : s.licenceType === 'timeTracking'
                        ? 'Zeiterfasserlizenz'
                        : 'Projektleiterlizenz';
                const amount = s.licenses || 1;
                data.push(`${amount} * ${article}`);
                data2.push(`${company?.partnerCustomerNumber || company?.customerNumber || ''}, ${company?.company || ''} ,${amount} * ${article}`);
                data.push(amount);
                data2.push(amount);
                data.push({ t: 'n', v: s.price ? s.price : 69, z: '#,##0.00' });
                data2.push({ t: 'n', v: s.price ? s.price / 2 : 69 / 2, z: '#,##0.00' });
                data.push({
                    t: 'n',
                    f: `F${row}*G${row}`,
                    z: '#,##0.00',
                });
                data2.push({
                    t: 'n',
                    f: `F${row}*G${row}`,
                    z: '#,##0.00',
                });
                rechnungenData.push(data);
                gutschriftenData.push(data2);
                row += 1;
                if (s.oneTimePrice &&
                    starts.getFullYear() === forDate.getFullYear() &&
                    starts.getMonth() === forDate.getMonth()) {
                    const data = [];
                    const data2 = [];
                    data.push(company?.partnerCustomerNumber || company?.customerNumber || '', company?.company || '');
                    data2.push(12510, 'upmesh');
                    data.push(`1.${forDate.getMonth() + 1}.${forDate.getFullYear()}`);
                    data2.push(`1.${forDate.getMonth() + 1}.${forDate.getFullYear()}`);
                    data.push(1018);
                    data2.push(1018);
                    const article = s.licenceType === 'collaborator'
                        ? 'Mitarbeiterlizenz'
                        : s.licenceType === 'timeTracking'
                            ? 'Zeiterfasserlizenz'
                            : 'Projektleiterlizenz';
                    const amount = s.licenses || 1;
                    data.push(`einmalig Kauf: ${amount} * ${article}`);
                    data2.push(`einmalig Kauf: ${company?.partnerCustomerNumber || company?.customerNumber || ''}, ${company?.company || ''} ,${amount} * ${article}`);
                    data.push(amount);
                    data2.push(amount);
                    data.push({ t: 'n', v: s.oneTimePrice, z: '#,##0.00' });
                    data2.push({ t: 'n', v: s.oneTimePrice, z: '#,##0.00' });
                    data.push({
                        t: 'n',
                        f: `F${row}*G${row}`,
                        z: '#,##0.00',
                    });
                    data2.push({
                        t: 'n',
                        f: `F${row}*G${row}`,
                        z: '#,##0.00',
                    });
                    rechnungenData.push(data);
                    gutschriftenData.push(data2);
                    row += 1;
                }
            }
        }
        workBook.Sheets['Rechnungen'] = XLSX.utils.aoa_to_sheet(rechnungenData, {
            cellDates: true,
        });
        workBook.Sheets['Gutschriften'] = XLSX.utils.aoa_to_sheet(gutschriftenData, {
            cellDates: true,
        });
        const filename = `${forDate.getFullYear()}_${(forDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}_Abrechnung`;
        await ExcelDownloadHelper.saveAndOpen(filename, workBook);
    }
}
