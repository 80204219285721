import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import NumberPicker from 'materialTheme/src/theme/components/NumberPicker';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { SubscriptionEntity } from 'upmesh-core/src/client/query/entities/SubscriptionEntity';
import { AddSubscriptionToCompany, } from 'upmesh-support-core/src/commands/company/AddSubscriptionToCompany';
import { ChangeSubscriptionFromCompany, } from 'upmesh-support-core/src/commands/company/ChangeSubscriptionFromCompany';
import * as uuid from 'uuid';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { SubscriptionExtended } from '../subscriptions/SubscriptionExtended';
export function CompanySubscriptionAdOrChangeDialog(props) {
    const newOne = useRef(props.subscription == null);
    const t = new Date();
    const [expireDate, setExpireDate] = useState(props.subscription
        ? props.subscription.expireDate
        : new Date(t.getFullYear() + 30, t.getMonth(), t.getDate(), 23, 59, 59, 59));
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(props.subscription ? props.subscription.startDate : new Date());
    const [currentSubAddData, setCurrentSubAddData] = useState(props.subscription
        ? props.subscription
        : new SubscriptionExtended({ licenses: 1, price: props.companyPayment.price, paymentIntervallInMonth: 12 }));
    const closeDialog = () => {
        Dialog.instance?.close();
    };
    const submitAddSubscription = async (licenses, testAccount, note = '', price, paymentIntervallInMonth, licenceType) => {
        setIsLoading(true);
        if (props.company == null)
            return;
        try {
            const data = {
                licenses,
                expireDate,
                testAccount,
                paymentIntervallInMonth,
                price: Math.round(price * 100) / 100,
                licenceType,
                note,
                startDate,
                companyId: props.company.id,
            };
            const add = new AddSubscriptionToCompany(data, uuid.v1(), ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const saveAddLicense = () => {
        submitAddSubscription(currentSubAddData.licenses, !!currentSubAddData.testAccount, currentSubAddData.note, currentSubAddData.price, currentSubAddData.paymentIntervallInMonth, currentSubAddData.licenceType).catch((err) => console.debug(err));
    };
    const submitChangeSubscription = async (id, licenses, testAccount, note, price, oneTimePrice, minimumTermInMonth, licenceType, paymentIntervallInMonth) => {
        setIsLoading(true);
        if (props.company == null)
            return;
        try {
            const data = {
                licenses,
                expireDate,
                testAccount,
                licenceType,
                oneTimePrice: oneTimePrice ? Math.round(oneTimePrice * 100) / 100 : 0,
                price: price ? Math.round(price * 100) / 100 : 0,
                minimumTermInMonth: minimumTermInMonth || undefined,
                note,
                paymentIntervallInMonth,
                startDate,
            };
            const add = new ChangeSubscriptionFromCompany(data, id, ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const changeLicense = () => {
        if (currentSubAddData.id == null)
            return;
        submitChangeSubscription(currentSubAddData.id, currentSubAddData.licenses, !!currentSubAddData.testAccount, currentSubAddData.note, currentSubAddData.price, currentSubAddData.oneTimePrice, currentSubAddData.minimumTermInMonth, currentSubAddData.licenceType, currentSubAddData.paymentIntervallInMonth).catch((err) => console.debug(err));
    };
    const onChangeLicenceType = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            licenceType: value.index === 0 ? null : value.index === 1 ? 'collaborator' : 'timeTracking',
            lastModifiedAt: new Date(),
            price: value.index === 0
                ? props.companyPayment.price
                : value.index === 1
                    ? props.companyPayment.priceCollaborator
                    : props.companyPayment.priceTimeTracking,
        }));
    };
    const onChangeExpireDate = (value) => {
        if (value != null)
            setExpireDate(value);
    };
    const onChangeStartDate = (value) => {
        if (value != null)
            setStartDate(value);
    };
    const onChangeLicences = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            licenses: value,
        }));
    };
    const onChangePaymentIntervall = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            paymentIntervallInMonth: value,
        }));
    };
    const onChangePrice = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            price: value,
        }));
    };
    const onChangeMinimumTermInMonth = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            minimumTermInMonth: value,
        }));
    };
    const onChangeOneTimePrice = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            oneTimePrice: value,
        }));
    };
    const onChangeTest = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            testAccount: value,
        }));
    };
    const onChangeNote = (value) => {
        setCurrentSubAddData(new SubscriptionEntity({
            ...currentSubAddData,
            note: value,
        }));
    };
    return (<>
      <DialogTitle key="title">{newOne.current ? 'Lizenz hinzufügen' : 'Lizenz bearbeiten'}</DialogTitle>
      <DialogContent key="content">
        {newOne.current && (<FormInputPicker labelText="Lizenzart" selectedIndex={currentSubAddData.licenceType === 'timeTracking'
                ? 2
                : currentSubAddData.licenceType === 'collaborator'
                    ? 1
                    : 0} list={[
                { title: 'Projektleiter', data: 'full' },
                { title: 'Mitarbeiter', data: 'collaborator' },
                { title: 'Zeiterfasser', data: 'timeTracking' },
            ]} onChange={onChangeLicenceType}/>)}
        <NumberPicker title="Anzahl Lizenzen" value={currentSubAddData.licenses} initValue={currentSubAddData.licenses ? currentSubAddData.licenses : 1} onChange={onChangeLicences} formatTime={false}/>
        <NumberPicker key={`price_${currentSubAddData.lastModifiedAt.toISOString()}`} title="Preis pro Monat" value={currentSubAddData.price ? currentSubAddData.price : 0} initValue={currentSubAddData.price ? currentSubAddData.price : 0} min={1} max={100} step={0.01} onChange={onChangePrice} formatTime={false}/>
        <NumberPicker key={`oneTimePrice_${currentSubAddData.lastModifiedAt.toISOString()}`} title="Preis Einmalig" step={0.01} value={currentSubAddData.oneTimePrice ? currentSubAddData.oneTimePrice : 0} initValue={currentSubAddData.oneTimePrice ? currentSubAddData.oneTimePrice : 0} onChange={onChangeOneTimePrice} formatTime={false}/>
        <NumberPicker key={`minimumTermInMonth_${currentSubAddData.lastModifiedAt.toISOString()}`} title="Mindestvertragslaufzeit in Monaten" value={currentSubAddData.minimumTermInMonth ? currentSubAddData.minimumTermInMonth : 0} initValue={currentSubAddData.minimumTermInMonth ? currentSubAddData.minimumTermInMonth : 0} onChange={onChangeMinimumTermInMonth} formatTime={false}/>
        <FormInputPicker labelText="Abrechnungsintervall" selectedIndex={currentSubAddData.paymentIntervallInMonth === 12
            ? 1
            : currentSubAddData.paymentIntervallInMonth === 24
                ? 2
                : 0} list={currentSubAddData.paymentIntervallInMonth === 1 ||
            currentSubAddData.paymentIntervallInMonth === 12 ||
            currentSubAddData.paymentIntervallInMonth === 24
            ? [
                { title: 'monatlich', data: '1' },
                { title: 'jährlich', data: '12' },
                { title: '2-jährlich', data: '24' },
            ]
            : [
                {
                    title: `alle ${currentSubAddData.paymentIntervallInMonth} Monate`,
                    data: currentSubAddData.paymentIntervallInMonth.toString(10),
                },
                { title: 'monatlich', data: '1' },
                { title: 'jährlich', data: '12' },
                { title: '2-jährlich', data: '24' },
            ]} onChange={(v) => {
            console.log('change intervall', v);
            onChangePaymentIntervall(Number.parseInt(v.value.data, 10));
        }}/>
        
        
        
        
        
        
        
        
        
        <DateInputFormFilled labelText="Startdatum" key={`expireDate_${startDate.getTime()}`} value={startDate} onChange={onChangeStartDate} selectDate selectTime={false} clearButton={false}/>
        <DateInputFormFilled labelText="Ablaufdatum" key={`expireDate_${expireDate.getTime()}`} value={expireDate} onChange={onChangeExpireDate} selectDate selectTime clearButton={false}/>
        <CheckboxListItem value={currentSubAddData.testAccount} title="kostenlos" onChange={onChangeTest}/>
        <FormInputFilled numberOfLines={5} initValue={currentSubAddData.note} labelText="Notizen" onChange={onChangeNote}/>
        
      </DialogContent>
      <DialogActions key="actions">
        <ContainedButton disabled={isLoading} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={closeDialog}/>
        <ContainedButton key="yes" loading={isLoading} title={!newOne.current ? 'ändern' : 'hinzufügen'} onPress={!newOne.current ? changeLicense : saveAddLicense}/>
      </DialogActions>
    </>);
}
export const openSubscriptionDialog = (e) => (company, companyPayment, subscription) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    Dialog.instance?.open({
        content: (<CompanySubscriptionAdOrChangeDialog subscription={subscription} companyPayment={companyPayment} company={company}/>),
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
    });
};
