import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ExpireSubscriptionFromCompany } from 'upmesh-support-core/src/commands/company/ExpireSubscriptionFromCompany';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { SubscriptionExtended } from '../subscriptions/SubscriptionExtended';
import { openSubscriptionDialog } from './CompanySubscriptionAdOrChangeDialog';
export function CompanySubscriptions(props) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [activeSegment, setActiveSegment] = useState(0);
    const updateData = () => {
        const client = ConnectionHandler.getClient();
        if (client == null)
            return;
        const f = activeSegment ? `lt` : `gt`;
        client.subscription
            .get({
            select: [
                'id',
                'licenses',
                'contractId',
                'expireDate',
                'startDate',
                'createdAt',
                'createdBy',
                'licenceType',
                'locked',
                'testAccount',
                'paymentIntervallInMonth',
                'price',
                'note',
                'oneTimePrice',
                'minimumTermInMonth',
            ],
            filter: `companyId eq '${props.company.id}' and licenses gt 0 and expireDate ${f} ${new Date().toISOString()}`,
        })
            .then((p) => {
            const promises = [];
            p.forEach((payment) => {
                promises.push(new Promise((resolve) => {
                    SubscriptionExtended.fromSubscription(payment)
                        .then((p) => resolve(p))
                        .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }));
            });
            return Promise.all(promises);
        })
            .then((p2) => {
            setSubscriptions(p2);
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    useEffect(() => {
        updateData();
    }, [activeSegment]);
    useEffect(() => {
        const l = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                let hasId = false;
                en.entities.forEach((e) => {
                    if (e.entity.companyId === props.company.id)
                        hasId = true;
                });
                if (hasId) {
                    updateData();
                }
            },
            readModelName: 'Subscription',
        });
        return () => {
            if (l)
                ConnectionHandler.getClient()?.eventDispatcher.detach('Subscription', l);
        };
    }, []);
    const expireSubscriptionNow = (sub) => (_e) => {
        LoadingEvents.instance.startLoading();
        Alert.instance?.close(() => {
            const remove = new ExpireSubscriptionFromCompany({}, sub.id, ConnectionHandler.getConnection().token);
            remove
                .execute(ConnectionHandler.getStore())
                .catch((e) => {
                console.error(e);
                Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            })
                .finally(() => {
                LoadingEvents.instance.stopLoading();
            });
        });
    };
    const expireSubscription = (sub) => (_e) => {
        Routing.instance.alert.post({
            text: 'Abo ablaufen lassen?',
            buttons: [
                <ContainedButton key="no" title="nein" onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                <ContainedButton key="yes" title="ja" onPress={expireSubscriptionNow(sub)}/>,
            ],
        });
    };
    const editSubscription = (item) => (e) => {
        if (item.price == null) {
            item.price =
                item.licenceType === 'collaborator'
                    ? props.companyPayment.priceCollaborator
                    : item.licenceType === 'timeTracking'
                        ? props.companyPayment.priceTimeTracking
                        : props.companyPayment.price;
        }
        if (item.paymentIntervallInMonth == null)
            item.paymentIntervallInMonth = 12;
        openSubscriptionDialog(e)(props.company, props.companyPayment, item);
    };
    const addSubscription = (e) => {
        openSubscriptionDialog(e)(props.company, props.companyPayment);
    };
    return (<View style={{ width: '100%', position: 'relative', height: props.height, overflow: 'visible' }}>
      <SegmentedButton multiselect={false} buttons={[{ title: 'aktive' }, { title: 'abgelaufene' }]} singleSelectSelected={activeSegment} onPress={(selected) => {
            setActiveSegment(selected);
        }}/>
      {subscriptions != null && subscriptions.length === 0 ? (<View style={{ padding: 16, width: '100%' }}>
          <MaterialText fixedWidth="100%" textAlign="center">
            Noch keine Lizenzen vorhanden
          </MaterialText>
        </View>) : (<Table data={subscriptions} tableName="CompanySubscriptions" actionItemsLength={2} actions={(_item) => {
                if (_item.expired || _item.expireDate.getTime() < new Date().getTime())
                    return [];
                return [
                    {
                        icon: 'pencil-outline',
                        onAction: editSubscription,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    { icon: 'delete-outline', onAction: expireSubscription, toolTip: I18n.m.getMessage('remove') },
                ];
            }} columns={[
                { title: 'Start', dataType: 'Date', keyInData: 'startDate', style: { minWidth: 200 } },
                { title: 'Ende', dataType: 'Date', keyInData: 'expireDate', style: { minWidth: 200 } },
                { title: 'Anzahl', dataType: 'number', keyInData: 'licenses', style: { minWidth: 100 } },
                {
                    title: 'Lizenz',
                    dataType: 'string',
                    keyInData: 'licenceType',
                    sortable: true,
                    style: { minWidth: 200 },
                    cellRenderer: (item, _column, index) => {
                        const text = item.licenceType == null
                            ? 'Projektleiterlizenzen'
                            : item.licenceType === 'collaborator'
                                ? 'Mitarbeiterlizenzen'
                                : 'Zeiterfassungslizenz';
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                    <MaterialText centeredBox>{text}</MaterialText>
                  </View>);
                    },
                },
                {
                    title: 'Abrechnungsintervall in Monaten',
                    dataType: 'number',
                    keyInData: 'paymentIntervallInMonth',
                    style: { minWidth: 200 },
                    minWidth: 200,
                },
                {
                    title: 'Mindestvertragslaufzeit in Monaten',
                    dataType: 'number',
                    keyInData: 'minimumTermInMonth',
                    style: { minWidth: 200 },
                    minWidth: 200,
                },
                {
                    title: 'Einmalig',
                    dataType: 'number',
                    keyInData: 'oneTimePrice',
                    style: { minWidth: 160 },
                    minWidth: 160,
                    format: (v) => v.oneTimePrice
                        ? v.oneTimePrice.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        : '-',
                },
                {
                    title: 'Einzelpreis pro Monat',
                    dataType: 'number',
                    keyInData: 'price',
                    style: { minWidth: 160 },
                    minWidth: 160,
                    format: (v) => v.price
                        ? v.price.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        : '-',
                },
                {
                    title: 'Preis pro Monat',
                    dataType: 'number',
                    keyInData: 'priceTotal',
                    style: { minWidth: 160 },
                    minWidth: 160,
                    format: (v) => v.priceTotal
                        ? v.priceTotal.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        : '-',
                },
                { title: 'Erstellt am', dataType: 'Date', keyInData: 'createdAt', style: { minWidth: 200 } },
                { title: 'Erstellt von', dataType: 'string', keyInData: 'creatorName', style: { minWidth: 200 } },
                {
                    title: 'Kostenlos',
                    dataType: 'string',
                    keyInData: 'testAccount',
                    style: { minWidth: 200 },
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                    <MaterialText fixedWidth="100%" textAlign="right">
                      {item.testAccount ? 'ja' : '-'}
                    </MaterialText>
                  </View>);
                    },
                },
                { title: 'Notiz', dataType: 'string', keyInData: 'note', style: { minWidth: 200 } },
            ]} maxHeight={props.height - 38}/>)}
      <View style={{ position: 'absolute', right: 16, bottom: 24 }}>
        <Icon onPress={addSubscription} icon="plus" color="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} radius={24} outerSize={48} toolTip="add"/>
      </View>
    </View>);
}
