var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateHeader = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DateHeader = exports.DateHeader = function (_PureComponent) {
  function DateHeader(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DateHeader);
    _this = _callSuper(this, DateHeader, [props]);
    _this.dateLocale = new _DateLocale.DateLocale('de');
    var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
    _this.dateLocale = new _DateLocale.DateLocale('de', timeFormat);
    return _this;
  }
  (0, _inherits2.default)(DateHeader, _PureComponent);
  return (0, _createClass2.default)(DateHeader, [{
    key: "renderDateAndTime",
    value: function renderDateAndTime() {
      var _this2 = this;
      var _this$props = this.props,
        date = _this$props.date,
        currentSelection = _this$props.currentSelection,
        mode = _this$props.mode;
      if (date == null) return [];
      var views = [];
      if (date instanceof Date) {
        if (mode === 'date' || mode === 'both') {
          views.push((0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
            onPress: function onPress() {
              if (_this2.props.activePicker === 'date') _this2.props.updateDate({
                newDay: date.getDay(),
                newMonth: date.getMonth(),
                newYear: date.getFullYear()
              });else _this2.props.setPicker('date');
            },
            textAlign: "left",
            type: _MaterialText.MaterialTextTypes.H6,
            color: "white",
            children: [this.props.weekdaysShort[date.getDay().toString()].replace('.', ''), ",", this.props.landscapeView ? '\n' : ' ', this.props.monthNamesShort[date.getMonth().toString()].replace('.', ''), " ", date.getDate()]
          }, "date_header_date"));
        }
        if (mode === 'time' || mode === 'both') {
          views.push((0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            onPress: function onPress() {
              if (_this2.props.activePicker === 'time') _this2.props.updateDate({
                newDay: date.getDay(),
                newMonth: date.getMonth(),
                newYear: date.getFullYear()
              });else _this2.props.setPicker('time');
            },
            textAlign: "left",
            type: _MaterialText.MaterialTextTypes.H6,
            color: "white",
            children: this.dateLocale.getLocalTimeString(date)
          }, "date_header_time"));
        }
      } else {
        views.push((0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [mode === 'date' || mode === 'both' ? (0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
              onPress: function onPress() {
                if (_this2.props.activePicker === 'date') _this2.props.setCurrentSelection('from');else _this2.props.setPicker('date');
              },
              textAlign: "left",
              type: _MaterialText.MaterialTextTypes.H6,
              color: currentSelection === 'from' && this.props.activePicker === 'date' ? _ThemeManager.ThemeManager.style.brandSecondary : 'white',
              children: [this.props.weekdaysShort[date.from.getDay().toString()].replace('.', ''), ",", this.props.landscapeView ? '\n' : ' ', this.props.monthNamesShort[date.from.getMonth().toString()].replace('.', ''), " ", date.from.getDate()]
            }) : null, mode === 'time' || mode === 'both' ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              onPress: function onPress() {
                if (_this2.props.activePicker === 'time') _this2.props.setCurrentSelection('from');else _this2.props.setPicker('time');
              },
              textAlign: "left",
              type: _MaterialText.MaterialTextTypes.H6,
              color: currentSelection === 'from' && this.props.activePicker === 'time' ? _ThemeManager.ThemeManager.style.brandSecondary : 'white',
              children: this.dateLocale.getLocalTimeString(date.from)
            }) : null]
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            onPress: function onPress() {
              return _this2.props.setPicker('date');
            },
            textAlign: "left",
            type: _MaterialText.MaterialTextTypes.H6,
            color: "white",
            children: ' - '
          }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [mode === 'date' || mode === 'both' ? (0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
              onPress: function onPress() {
                if (_this2.props.activePicker === 'date') _this2.props.setCurrentSelection('to');else _this2.props.setPicker('date');
              },
              textAlign: "left",
              type: _MaterialText.MaterialTextTypes.H6,
              color: currentSelection === 'to' && this.props.activePicker === 'date' ? _ThemeManager.ThemeManager.style.brandSecondary : 'white',
              children: [this.props.weekdaysShort[date.to.getDay().toString()].replace('.', ''), ",", this.props.landscapeView ? '\n' : ' ', this.props.monthNamesShort[date.to.getMonth().toString()].replace('.', ''), " ", date.to.getDate()]
            }) : null, mode === 'time' || mode === 'both' ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              onPress: function onPress() {
                if (_this2.props.activePicker === 'time') _this2.props.setCurrentSelection('to');else _this2.props.setPicker('time');
              },
              textAlign: "left",
              type: _MaterialText.MaterialTextTypes.H6,
              color: currentSelection === 'to' && this.props.activePicker === 'time' ? _ThemeManager.ThemeManager.style.brandSecondary : 'white',
              children: this.dateLocale.getLocalTimeString(date.to)
            }) : null]
          })]
        }, "date_header_date"));
      }
      return views;
    }
  }, {
    key: "render",
    value: function render() {
      var hideInputModeIcon = this.props.hideInputModeIcon;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: this.props.landscapeView ? '100%' : 120,
          width: this.props.landscapeView ? 196 : undefined,
          backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 24
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            height: 32,
            paddingBottom: this.props.landscapeView ? 4 : undefined
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Overline,
            color: "white",
            children: this.props.labelText !== undefined ? this.props.labelText.toUpperCase() : this.props.activePicker === 'date' ? _I18n.I18n.m.getMessage('selectDate').toUpperCase() : _I18n.I18n.m.getMessage('selectTime').toUpperCase()
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingTop: this.props.landscapeView ? 12 : undefined,
            height: this.props.landscapeView ? '100%' : 72,
            justifyContent: 'flex-end'
          },
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              justifyContent: 'space-between',
              height: this.props.landscapeView ? '100%' : undefined,
              flexDirection: this.props.landscapeView ? 'column' : 'row',
              flex: this.props.landscapeView ? 1 : undefined,
              marginBottom: this.props.landscapeView ? 24 : 0
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              children: this.renderDateAndTime()
            }), hideInputModeIcon ? null : (0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: this.props.activePage === 'picker' ? 'keyboard' : 'calendar',
              iconSize: 24,
              toolTip: _I18n.I18n.m.getMessage('toggleInput'),
              onPress: this.props.togglePage,
              color: "white"
            }), this.props.children]
          })
        })]
      });
    }
  }]);
}(_react.PureComponent);